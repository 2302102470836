import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/shared/services/common.service';
import { DOCUMENT } from '@angular/common';
import { DataService } from 'src/shared/services/data.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @ViewChild('f') f: NgForm;
  signUpObj = {
    name: '',
    email: '',
  };
  signUpResponses = '';
  TrendingAdventures = [];
  activeCategory = 'DESTINATIONS';
  category = [
    'DESTINATIONS',
    'OUR TRAVEL STYLES',
    'MOST POPULAR',
    'UNIQUE',
    'VIEW ALL',
  ];
  destinations = [];
  signUpDone: boolean = false;
  emailPattern = this.commonService.emailPattern;
  constructor(private router: Router, private commonService: CommonService,
    private messageService: MessageService,
    private renderer: Renderer2, private dataService: DataService,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    // this.getTrendingAdventures();
    this.addDestinations();
    const showtext = document.querySelectorAll('.showtext');
    const dottedlinecover = document.querySelectorAll('.dotted-linecover');
    const bike = document.querySelectorAll('.bike');
    const linecover = document.querySelectorAll('.line-cover');
    const singlewave = document.querySelectorAll('.single-wave');
    const single1wave = document.querySelectorAll('.single1-wave');
    const appearOnScroll = new IntersectionObserver(function (
      entries,
      appearOnScroll
    ) {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        } else {
          let classToAdd = '';
          if (entry.target.classList.contains('showtext')) {
            classToAdd = 'showMe';
          } else if (entry.target.classList.contains('dotted-linecover')) {
            classToAdd = 'reveal-dottedline';
          } else if (entry.target.classList.contains('bike')) {
            classToAdd = 'move-bike';
          } else if (entry.target.classList.contains('line-cover')) {
            classToAdd = 'reveal-line';
          } else if (entry.target.classList.contains('single-wave')) {
            classToAdd = 'reveal-singlewave';
          } else if (entry.target.classList.contains('single1-wave')) {
            classToAdd = 'reveal-single1wave';
          }
          entry.target.classList.add(classToAdd);
          appearOnScroll.unobserve(entry.target);
        }
      });
    });
    showtext.forEach((text) => {
      appearOnScroll.observe(text);
    });
    dottedlinecover.forEach((text) => {
      appearOnScroll.observe(text);
    });
    bike.forEach((text) => {
      appearOnScroll.observe(text);
    });
    linecover.forEach((text) => {
      appearOnScroll.observe(text);
    });
    singlewave.forEach((text) => {
      appearOnScroll.observe(text);
    });
    single1wave.forEach((text) => {
      appearOnScroll.observe(text);
    });
    dottedlinecover.forEach((element) => {
      element.addEventListener('animationend', () => {
        element.classList.add('d-none');
      });
    });
    bike.forEach((element) => {
      element.addEventListener('animationend', () => {
        element.classList.add('newbike');
      });
    });
    linecover.forEach((element) => {
      element.addEventListener('animationend', () => {
        element.classList.add('d-none');
      });
    });
    singlewave.forEach((element) => {
      element.addEventListener('animationend', () => {
        element.classList.add('d-none');
      });
    });
    single1wave.forEach((element) => {
      element.addEventListener('animationend', () => {
        element.classList.add('d-none');
      });
    });
  }
  joinUs(form: NgForm) {
    if (form.valid) {
      this.signUpDone = true;
      setTimeout(() => {
        this.signUpDone = false;
      }, 20000);
      const payload = {
        name: this.signUpObj.name,
        email: this.signUpObj.email,
        type: 'HOME PAGE',
      };
      this.commonService.addEmail(payload).subscribe((res: any) => {
        if (res.code == 'SUCCESS' && res.message == 'EMAIL_ADDED') {
          this.f.resetForm();
          this.signUpResponses = 'Thanks for reaching us!';
        } else {
          this.messageService.add({ severity: 'info', summary: 'Info', detail: res.message });
        }
      },
        (error) => {
          this.f.resetForm();
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server error occured!' });
        }
      );
    }
  }
  changeCategory(category: any) {
    this.activeCategory = category;
    if (category == 'VIEW ALL') {
      this.router.navigate(['/other/explore']);
    }
  }
  redirectToCity(city: any) {
    this.router.navigateByUrl(`/things-to-do-in-${city}`);
  }
  loadTour(name) {
    let result = this.commonService.generateTourName(name, 'lonavala');
    this.router.navigate(['/ToursandActivities', result]);
  }
  addDestinations() {
    this.destinations = this.dataService.getHomePageDestination();
  }
  goToTabs() {
    const category = document.getElementById('destination');
    category.scrollIntoView();
  }

  loadBlogDetail(name) {
    let result = this.commonService.getEncodedTitle(name);
    this.router.navigate(['/other/blog-detail', result]);
  }
}
