import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { BookingModule } from './booking/booking.module';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  {
    path: 'what-is-strode',
    loadChildren: () =>
      import('./what-is-strode/what-is-strode.module').then(
        (m) => m.WhatIsStrodeModule
      ),
  },
  {
    path: 'all-the-info',
    loadChildren: () =>
      import('./all-the-info/all-the-info.module').then(
        (m) => m.AllTheInfoModule
      ),
  },
  {
    matcher: displayCities,
    loadChildren: () =>
      import('./destination/destination').then((m) => m.DestinationModule),
  },
  {
    path: 'toursandactivities/:tourInfo',
    loadChildren: () =>
      import('./itinerary/itinerary.module').then((m) => m.ItineraryModule),
  },
  {
    path: 'booking',
    loadChildren: () =>
      import('./booking/booking.module').then((m) => BookingModule),
  },
  {
    path: 'policy',
    loadChildren: () =>
      import('./policy/policy.module').then((m) => m.PolicyModule),
  },
  {
    path: 'other',
    loadChildren: () =>
      import('./other-pages/other-pages.module').then(
        (m) => m.OtherPagesModule
      ),
  },
];
export function displayCities(url: any) {
  if (url.length > 0 && url[0].path.startsWith('things-to-do-in-')) {
    return { consumed: [url[0]] };
  }
  return null;
}


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
