import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as AOS from 'aos';
import { filter } from 'rxjs/operators';
import { CanonicalService } from 'src/shared/services/canonical.service';
import { MetaService } from 'src/shared/services/meta.service';
import { SchemaService } from 'src/shared/services/schema.service';
declare var gtag
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'strode-web';
  constructor(private router: Router,
    private canonicalService: CanonicalService,
    private metaTagService: MetaService,
    private schemaService: SchemaService
  ) {
    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      gtag('config', 'G-2GZQXJW652', { 'page_path': e.urlAfterRedirects });
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const currentPath = event.urlAfterRedirects || event.url;
        if (currentPath.split('/')[1] !== "toursandactivities") {
          this.metaTagService.setOgImage()
        }
        this.canonicalService.createCanonicalURL();
        this.schemaService.loadSchema();
      }
    });
  }

  ngOnInit() {
    AOS.init({
      once: true,
      easing: 'ease-in-out',
      duration: 2000
    });
  }
}


