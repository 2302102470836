import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    private TOUR_SERVICE = 'https://pwdbooking.onnbikes.com/tour-service/';
    private IMG_BASE_URL = "https://d3ctzkcim3mheb.cloudfront.net/strodeexperiences-live-media";
    constructor(private httpClient: HttpClient) { }
    citydata = {
        activeCity: '',
        title: '',
        metaTitle: '',
        keywords: '',
        country: '',
        brochure_img: '',
        metaDesc: '',
        cityname: '',
        caouselOneTitle: '',
        caouselTwoTitle: '',
        caouselThreeTitle: '',
        shortDesc: [''],
        exploreDesc: '',
        discoverMore: '',
        moverInStyle: '',
        betterOptions: '',
        explore: [
            {
                title: '',
                desc: '',
                img: '',
            }
        ],
        description: [
            {
                info: '',
            }
        ],
        bannerImg: '',
        centerImg: ''
    };
    getDestinationData(data: any) {

        this.citydata.shortDesc = [];
        this.citydata.explore = [];
        this.citydata.description = [];
        this.citydata.activeCity = data?.city.toLowerCase();
        this.citydata.title = data?.title;
        this.citydata.metaTitle = this.getDestinationMetaTitle(data?.city);
        this.citydata.country = data?.country;
        this.citydata.brochure_img = data?.virtual_experience?.brochure_image?.uuid;
        this.citydata.metaDesc = data?.meta_desc;
        this.citydata.cityname = data?.city.toLowerCase();
        this.citydata.caouselOneTitle = '2 Days Itinerary for ' + data?.city;
        this.citydata.caouselTwoTitle = 'Top 10 Adventures to do in ' + data?.city;
        this.citydata.caouselThreeTitle = '8 Amazing Places To See in ' + data?.city;
        this.citydata.discoverMore = data?.discover_more_desc;
        this.citydata.betterOptions = data?.better_option_desc;
        this.citydata.exploreDesc = data?.explore?.short_desc;
        this.citydata.moverInStyle = data?.move_in_style_desc;
        if (data?.short_desc.length > 0) {
            this.citydata.shortDesc = data?.short_desc?.[0];
        }
        if (data?.our_perspective.length > 0) {
            data?.our_perspective.forEach((ele: any) => {
                let obj = {
                    title: ele['title'],
                    desc: ele['description'],
                    img: ele['image']
                }
                this.citydata.explore.push(obj);
            })
        }
        if (data?.explore?.long_desc.length > 0) {
            data?.explore?.long_desc.forEach((ele: any) => {
                let obj = {
                    info: ele,
                }
                this.citydata.description.push(obj);
            })
        }
        if (data.hasOwnProperty('virtual_experience')) {
            this.citydata.bannerImg = data?.virtual_experience?.banner_image?.uuid;
            if (this.citydata?.explore.length > 0) {
                this.citydata.centerImg = this.citydata?.explore?.[this.citydata.explore.length - 1]?.['img'];
            }
        }
        return this.citydata;
    }
    getTourcategory() {
        let tourCategoryList = [
            {
                categoryName: 'Local Lovers',
                backgroundImg:
                    this.IMG_BASE_URL + '/destination/foodcatrgory-min.jpg',
            },
            {
                categoryName: 'Night Nomads',
                backgroundImg:
                    this.IMG_BASE_URL + '/destination/nightowl-min.jpg',
            },
            {
                categoryName: 'Historians',
                backgroundImg:
                    this.IMG_BASE_URL + '/destination/history-min.jpg',
            },
            {
                categoryName: 'Adventurers',
                backgroundImg:
                    this.IMG_BASE_URL + '/destination/Adventures-min.jpg',
            },
            {
                categoryName: 'Art Admirers',
                backgroundImg:
                    this.IMG_BASE_URL + '/destination/Art_lovers-min.jpg',
            },
        ];
        return tourCategoryList;
    }
    getOfferCarousel() {
        let offerCarousel = [
            {
                img: this.IMG_BASE_URL + '/destination/offer1-min.webp',
                heading: 'Crazy Deals',
                method: 'this.showDialog()',
            },
            {
                img: this.IMG_BASE_URL + '/destination/offer3.webp',
                heading: 'Tips n Secrets',
                method: 'this.showDialogTwo()',
            },
            {
                img: this.IMG_BASE_URL + '/explore/offers/Customize.webp',
                heading: 'Wanna Customise?',
                method: 'this.showDialogThree()',
            },
        ];
        return offerCarousel;
    }
    getOfferImage() {
        let offerImg = [
            {
                img: this.IMG_BASE_URL + '/destination/offer1-min+(1).webp',
                code: 'GOBIG',
            },
            {
                img: this.IMG_BASE_URL + '/destination/offer2-min+(1).webp',
                code: 'GOCOUPLE',
            },
            {
                img: this.IMG_BASE_URL + '/destination/offer3-min.webp',
                code: 'GOSOLO',
            },
        ];

        return offerImg;
    }
    getExploreOfferImage() {
        let offersImg = [
            {
                img: '',
                headtitle: 'One is Fun : Solo Savers!',
                thumbnail: this.IMG_BASE_URL + '/explore/new_offers/solo.webp',
                text: 'PRIVATE GROUP <br> ADVENTURES',
                info: ` Get flat 10% off on your first booking <br class="d-md-none">  (upto INR 125)`,
                code: 'GOSOLO',
                title: 'OFFER TERMS',
                offer: 'Offers for you',
                points: [
                    'This offer is valid on all payment modes across all our destinations in India.',
                    'The offer is valid once per user per experience booking with us.',
                    'In case of partial/full cancellation the offer stands void.',
                    'User Agreement and Privacy Policy of the Strode Experiences website will apply.',
                ],
            },
            {
                img: '',
                headtitle: 'Twogether, Pay Less Together',
                thumbnail: this.IMG_BASE_URL + '/explore/new_offers/offers+image.webp',
                text: 'CUSTOMIZE YOUR <br> EXPERIENCE',
                info: `Get flat 15% off on your booking  <br class="d-md-none"> (upto INR 300)`,
                code: 'GOCOUPLE',
                title: 'OFFER TERMS',
                offer: 'Offers for  you',
                points: [
                    'This offer is valid on all payment modes across all our destinations in India.',
                    'The offer is valid once for a group of 2+ or incase of 2 or more experience bookings with us.',
                    'In case of partial/full cancellation the offer stands void.',
                    'User Agreement and Privacy Policy of the Strode Experiences website will apply.',
                ],
            },
            {
                img: '',
                headtitle: 'Gangbusters of Bargains!',
                thumbnail: this.IMG_BASE_URL + '/explore/new_offers/group.webp',
                text: 'YOU DESIGN,<br> WE LEAD',
                code: 'GOBIG',
                info: `Get flat 15% off on your  booking <br class="d-md-none"> (upto INR 500)`,
                title: 'OFFER TERMS',
                offer: 'Offers for  you',
                points: [
                    'This offer is valid on all payment modes across all our destinations in India.',
                    'The offer is valid once for a group of 5+ or incase of 5 or more experience bookings with us.',
                    'In case of partial/full cancellation the offer stands void.',
                    'User Agreement and Privacy Policy of the Strode Experiences website will apply.',
                ],
            },
            {
                img: '',
                headtitle: "Tailored Getaways for Groups",
                thumbnail: this.IMG_BASE_URL + '/explore/new_offers/private.webp',
                text: 'Unlock the Perks of <br> Going Solo',
                offer: 'Private Group  Adventures',
                info: `<p class="m-0 mt-md-0 mt-5 text-white">Whether it's an intimate family getaway, a friends' reunion, or corporate retreats, we curate experiences that reflect your dreams, tastes, and shared history.</p>`,
            },
            {
                img: '',
                headtitle: 'Your Trip, Your Rules',
                thumbnail: this.IMG_BASE_URL + '/explore/new_offers/customise.webp',
                text: "Two's Company: Savings <br> for Sweethearts",
                info: '<p class="m-0 mt-md-0 mt-5 text-white">From the interests of a dinosaur-loving nine-year-old to adults craving some personal downtime, we customize every aspect to ensure a journey that suits your individual preferences.</p>',
                offer: 'Customize Your  Experience',
            },
            {
                img: '',
                headtitle: 'Tailored Adventures with Strode',
                thumbnail: this.IMG_BASE_URL + '/explore/new_offers/you+design.webp',
                text: "Family Fun, Frugal Savings : <br> Here's your cheat code",
                offer: 'You Design,  We Lead',
                info: '<p class="m-0 mt-md-0 mt-5 text-white">Craft your own travel journey instead of settling for a pre-determined package, ensuring every moment aligns with your desires.</p>',
            },
        ];
        return offersImg;
    }
    getExploreDestinations() {
        let carousel = [
            {
                img: this.IMG_BASE_URL + '/explore/udaipurbanner.webp',
                text: "Ditch the ordinary in Udaipur: From boat rentals and vintage car  rides to Marwari horseback journeys  and e-bike wildlife  explorations, it's a whole new Udaipur waiting to be discovered.",
                city: 'Udaipur',
                color: 'black',
            },
            {
                img: this.IMG_BASE_URL + '/explore/jaipurbanner.webp',
                text: `Beyond Amber Fort and Hawa Mahal, Jaipur's got the quirkiest treasures: Monkey Temple,
      16th century step wells, Elephant Hugs, and Antique Art Galleries.
      Let's dive into  the unusual to do's with Strode.`,
                city: 'Jaipur',
                color: 'black',
            },
            {
                img: this.IMG_BASE_URL + '/explore/amsterdambanner.webp',
                text: 'From the Cheese Museum, Floating Cat Sanctuary,  and  Microbes Zoo to the Icebar  Club – Join  Strode for  a wild ride in Amsterdam!.',
                city: 'Amsterdam',
                color: 'white',
            },
            {
                img: this.IMG_BASE_URL + '/explore/lonavalabanner.webp',
                text: "From kayaking on a private lake and forest yoga   sessions  to snake expeditions and unraveling the art of 'Man vs Wild,'  embrace the extraordinary  in the Sahyadris.",
                city: 'Lonavala',
                color: 'white',
            },
        ];
        return carousel;
    }
    getExploreMobDestinations() {
        let carousel = [
            {
                img: this.IMG_BASE_URL + '/explore/udaipur.webp',
                text: "Ditch the ordinary in Udaipur: From boat rentals and vintage car  rides to Marwari horseback journeys  and e-bike wildlife  explorations, it's a whole new Udaipur waiting to be discovered.",
                city: 'Udaipur',
                color: 'white',
            },
            {
                img: this.IMG_BASE_URL + '/explore/jaipur-min.webp',
                text: `Beyond Amber Fort and Hawa Mahal, Jaipur's got the quirkiest treasures: Monkey Temple,
                      16th century step wells, Elephant Hugs, and Antique Art Galleries.
                      Let's dive into  the unusual to do's with Strode.`,
                city: 'Jaipur',
                color: 'white',
            },
            {
                img: this.IMG_BASE_URL + '/explore/amstardam-min.webp',
                text: 'From the Cheese Museum, Floating Cat Sanctuary,  and  Microbes Zoo to the Icebar  Club – Join  Strode for  a wild ride in Amsterdam!.',
                city: 'Amsterdam',
                color: 'white',
            },
            {
                img: this.IMG_BASE_URL + '/explore/lonavala-min.webp',
                text: "From kayaking on a private lake and forest yoga   sessions  to snake expeditions and unraveling the art of 'Man vs Wild,'  embrace the extraordinary  in the Sahyadris.",
                city: 'Lonavala',
                color: 'white',
            },
        ];
        return carousel;
    }
    getReadUpContent() {
        let content = [
            {
                img: this.IMG_BASE_URL + '/destination/nightowl-min.jpg',
                imgDesc: `Strodeis an experiential travel company. We design rare activities, remote trails, themed tours
                          and micro adventures.`,
                title: "Read-Up",
                pointDesc: [
                    {
                        title: "Experiential Pioneers",
                        icon: `<i class="fa fa-bell font-24 px-1"></i>`,
                        text: "Strode redefines experiential travel, crafting rare activities, remote trails, themed tours, and micro-adventures in select destinations."
                    },
                    {
                        title: "Phygital Innovation",
                        icon: `<i class="fa fa-map font-24 px-1"></i>`,
                        text: "We pioneer phygital travel, seamlessly blending innovative technology with genuine human connection for an immersive journey."
                    },
                    {
                        title: "Rediscover the Joy",
                        icon: `<i class="fa fa-snowflake-o font-24 px-1"></i>`,
                        text: "Let us reintroduce you to the thrill of travel - the joy of new discoveries, heartwarming connections with strangers, and the sense of achievement from learning new skills."
                    },
                    {
                        title: "Hassle-Free Exploration",
                        icon: `<i class="fa fa-life-bouy font-24 px-1"></i>`,
                        text: "Count on us for extensive research and meticulous curation, ensuring your travel planning is hassle-free, providing ease, convenience, and freedom for your adventure."
                    }
                ]
            },
            {
                img: this.IMG_BASE_URL + '/how-it-works/stickguy+png.png',
                imgDesc: `Strodeis an experiential travel company. We design rare activities, remote trails, themed tours
                          and micro adventures.`,
                title: "WHAT TO EXPECT",
                pointDesc: [
                    {
                        title: "Curated Selection, Not Overwhelming",
                        icon: `<i class="fa fa-bell font-24 px-1"></i>`,
                        text: "Choose from 25 thoughtfully curated experiences at each destination, avoiding the overwhelm of countless options typical of other platforms."
                    },
                    {
                        title: "Handpicked and Vetted",
                        icon: `<i class="fa fa-map font-24 px-1"></i>`,
                        text: "Our on-ground experience designers collaborate with local operators to bring you hidden gems, ensuring each experience is carefully vetted for quality and authenticity."
                    },
                    {
                        title: "Immersive, Not Touristy",
                        icon: `<i class="fa fa-snowflake-o font-24 px-1"></i>`,
                        text: "Expect immersive and transformative adventures—whether it's trails, tours, or micro-adventures—crafted to provide a unique connection with the destination, steering away from typical touristy offerings."
                    },
                    {
                        title: "Simplified Planning",
                        icon: `<i class="fa fa-life-bouy font-24 px-1"></i>`,
                        text: "We prioritize simplicity over complexity, offering options, personalization, and convenience without bombarding you, ensuring stress-free travel planning."
                    }
                ]
            },
            {
                img: this.IMG_BASE_URL + '/how-it-works/how-to-book-min.webp',
                imgDesc: `Strodeis an experiential travel company. We design rare activities, remote trails, themed tours
                          and micro adventures.`,
                title: "HOW TO BOOK",
                pointDesc: [
                    {
                        title: "User-Friendly Platform",
                        icon: `<i class="fa fa-bell font-24 px-1"></i>`,
                        text: " Easily plan and book your holiday activities through our intuitive website or mobile app, offering a seamless and convenient experience."
                    },
                    {
                        title: "Personalized Assistance",
                        icon: `<i class="fa fa-map font-24 px-1"></i>`,
                        text: "Offline concierges are ready to assist you at destination properties, providing personalized recommendations for a unique and tailored travel experience."
                    },
                    {
                        title: "Strode E-Bikes for Exploration",
                        icon: `<i class="fa fa-snowflake-o font-24 px-1"></i>`,
                        text: "Explore destinations effortlessly with our exclusive Strode e-bikes, available at partner accommodations, allowing you to examine trails, tours, and activities at your own pace"
                    },
                    {
                        title: "Simple Booking Process",
                        icon: `<i class="fa fa-life-bouy font-24 px-1"></i>`,
                        text: "Choose your preferred time slot, travel style, and payment option with just a few clicks, ensuring a flexible and hassle-free booking process for your next adventure."
                    }
                ]
            },
            {
                img: this.IMG_BASE_URL + '/how-it-works/one-last-thing-min.webp',
                imgDesc: `Strodeis an experiential travel company. We design rare activities, remote trails, themed tours
                          and micro adventures.`,
                title: "ONE LAST THING",
                pointDesc: [
                    {
                        title: "Diverse Experiences Await",
                        icon: `<i class="fa fa-bell font-24 px-1"></i>`,
                        text: "From cultural tours to adventurous activities, Strode offers a diverse range of experiences, ensuring there's something for every type of traveler."
                    },
                    {
                        title: "Eco-Conscious Travel",
                        icon: `<i class="fa fa-map font-24 px-1"></i>`,
                        text: "Proudly committed to a planet-positive partnership, all our tours and trails are conducted on eco-friendly e-bikes, minimizing our environmental footprint."
                    },
                    {
                        title: "Transportation Freedom",
                        icon: `<i class="fa fa-snowflake-o font-24 px-1"></i>`,
                        text: "When you book with us, you have the freedom to choose between your preferred vehicle or our eco-friendly e-bikes, adding flexibility to your travel plans."
                    },
                    {
                        title: "Memorable and Responsible",
                        icon: `<i class="fa fa-life-bouy font-24 px-1"></i>`,
                        text: " Immerse yourself in memorable experiences while contributing to responsible travel practices, as Strode ensures a positive impact on the environment and local communities."
                    }
                ]
            },
        ]
        return content;
    }

    getDestinationMetaTitle(city) {
        let metaTitle = [
            { city: 'Lonavala', title: 'Explore the Best Things to Do in Lonavala: Attractions, Activities & More' },
            { city: 'Jaipur', title: 'Explore Unforgettable Jaipur: Top Things to Do in Jaipur and Must-See Attractions' },
            { city: 'Udaipur', title: 'Explore Unforgettable Adventures: Things to Do in Udaipur, India' },
            { city: 'Amsterdam', title: 'Explore the Best Things to Do in Amsterdam | Must-See Attractions' },
        ]
        let data = metaTitle.filter((ele) => ele.city == city);
        if (data) {
            return data[0]['title']
        }
    }
    getHomePageDestination() {
        let destinations = [
            {
                city: 'Lonavala',
                img: this.IMG_BASE_URL + '/home/destination/d1.webp',
            },
            {
                city: 'Jaipur',
                img: this.IMG_BASE_URL + '/home/destination/d2.webp',
            },
            {
                city: 'Udaipur',
                img: this.IMG_BASE_URL + '/home/destination/d3.webp',
            },
            {
                city: 'Amsterdam',
                img: this.IMG_BASE_URL + '/home/destination/d4.webp',
            },
        ];
        return destinations;
    }
    getExploreTourCategoryData() {
        let categoryData = [
            {
                title: 'MORNING',
                desc: 'Before the Bustle Begins, go for tranquil adventures, photography, and discover the morning routines in the first light.'
            },
            {
                title: 'MID-MORNING',
                desc: 'Embrace unhurried magic, avoid aimless crowds, and immerse in local rhythm before the tourist hordes gather at popular spots.'
            },
            {
                title: 'AFTERNOON',
                desc: 'Set aside your afternoons for acquiring fresh skills, nurturing local traditions, and weaving local connections.'
            },
            {
                title: 'EVENING',
                desc: 'Discover peaceful countrysides, savor private moments during sunsets and chase soft adventures far from the madding crowd.'
            },
            {
                title: 'NIGHT',
                desc: `Take pub strolls, admire city's nightlife, savor local delicacies, and chit- chat with local youth - a perfect way to conclude your day.`
            },
        ]
        return categoryData;
    }
    getResourceDestination() {
        let destinations = [
            {
                city: 'lonavala',
                type: 'Lonavala',
                desc: 'Navigating a neighborhood is like exploring',
                // img: 'https://ik.imagekit.io/strode/resources/Lonavala121.webp?updatedAt=1701495053914'
                img: this.IMG_BASE_URL + '/New+Images/Travel+Resources+Lonavala.webp',
            },
            {
                city: 'udaipur',
                type: 'Udaipur',
                desc: 'Navigating a neighborhood is like exploring',
                img: this.IMG_BASE_URL + '/resources/iStock-157572027.webp',
            },
            {
                city: 'jaipur',
                type: 'Jaipur',
                desc: 'Navigating a neighborhood is like exploring',
                // img: 'https://ik.imagekit.io/strode/resources/JPR32.webp?updatedAt=1701492471666'
                img: this.IMG_BASE_URL + '/New+Images/Jaipur+Travel+Resources.webp',
            },
            {
                city: 'amsterdam',
                type: 'Amsterdam',
                desc: 'Navigating a neighborhood is like exploring',
                img: this.IMG_BASE_URL + '/resources/Amsterdam12131.webp?',
            },
            {
                city: 'london',
                type: 'London',
                desc: 'Navigating a neighborhood is like exploring',
                img: this.IMG_BASE_URL + '/resources/Amsterdam12131.webp?',
            },
            {
                city: 'milan',
                type: 'Milan',
                desc: 'Navigating a neighborhood is like exploring',
                img: this.IMG_BASE_URL + '/resources/Amsterdam12131.webp?',
            },
            {
                city: 'paris',
                type: 'Paris',
                desc: 'Navigating a neighborhood is like exploring',
                img: this.IMG_BASE_URL + '/resources/Amsterdam12131.webp?',
            },
        ]
        return destinations;
    }
    getResources() {
        let resources = [
            {
                title: 'Blogs',
                description: `Travelogues, Tips & Tales where we share our in-depth insights on places`,
                buttonText: 'Explore',
                buttonMethod: 'this.openBlog()',
                img: this.IMG_BASE_URL + '/resources/Top+10+Offbeat+Locations+in+Jaipur+(3).webp',

            },
            {
                title: 'Stories',
                description: `These quick reads speak volumes, often in 300 words or less…`,
                buttonText: 'Explore',
                buttonMethod: 'this.openDestinations()',
                img: this.IMG_BASE_URL + '/resources/Top+10+Offbeat+Locations+in+Jaipur+(5).webp',
            },
            {
                title: 'Bites',
                description: `For some rapid-fire travel insights`,
                buttonText: 'Explore',
                buttonMethod: 'this.openJournals()',
                img: this.IMG_BASE_URL + '/resources/Top 10 Offbeat Locations in Jaipur (4).webp',
            },
            {
                title: 'Reels ',
                description: `Unlock local and intriguing 'why's & how’s' through visual highlights`,
                buttonText: 'Explore',
                buttonMethod: 'this.openCarousel()',
                img: this.IMG_BASE_URL + '/resources/Top+10+Offbeat+Locations+in+Jaipur+(6).webp',
            },

        ]
        return resources;
    }
    getTravelCarousel() {
        let travelCarousel = [
            {
                title: "common travel <br class='mob-display-none'> mistakes",
                des: "Navigating a neighborhood is like exploring a small city, and we get it—travel time is precious. We've been there, hopping between places that are at different ends of the city, standing in lines when we reach a right place at a wrong time, and sometimes, feeling lost when we reach a place that doesnt matches to our travel style. That's why we've revamped our approach",
                bannerImg: this.IMG_BASE_URL + "/resources/iStock-1766990607.webp"

            },
            {
                title: "common travel <br class='mob-display-none'> mistakes",
                des: "Navigating a neighborhood is like exploring a small city, and we get it—travel time is precious. We've been there, hopping between places that are at different ends of the city, standing in lines when we reach a right place at a wrong time, and sometimes, feeling lost when we reach a place that doesnt matches to our travel style. That's why we've revamped our approach",
                bannerImg: this.IMG_BASE_URL + "/resources/iStock-1439775184.webp"
            },
            {
                title: "common travel <br class='mob-display-none'> mistakes",
                des: "Navigating a neighborhood is like exploring a small city, and we get it—travel time is precious. We've been there, hopping between places that are at different ends of the city, standing in lines when we reach a right place at a wrong time, and sometimes, feeling lost when we reach a place that doesnt matches to our travel style. That's why we've revamped our approach",
                bannerImg: this.IMG_BASE_URL + "/resources/iStock-1448063736.webp"
            },
        ]

        return travelCarousel;
    }
    getNeighbourhoodCategories() {
        let categories = [
            {
                name: "artadmirers",
                img: "https://icons.veryicon.com/png/o/miscellaneous/category-icon-set/category-56.png"
            },
            {
                name: "local lovers",
                img: "https://icons.veryicon.com/png/o/miscellaneous/category-icon-set/category-56.png"
            },
            {
                name: "ANIMALS",
                img: "https://icons.veryicon.com/png/o/miscellaneous/category-icon-set/category-56.png"
            },
            {
                name: "ARTS & CULTURE",
                img: "https://icons.veryicon.com/png/o/miscellaneous/category-icon-set/category-56.png"
            },
            {
                name: "BEAUTY & FASHION",
                img: "https://icons.veryicon.com/png/o/miscellaneous/category-icon-set/category-56.png"
            },
            {
                name: "DESIGN & DIY",
                img: "https://icons.veryicon.com/png/o/miscellaneous/category-icon-set/category-56.png"
            },
            {
                name: "ENTERTAINMENT",
                img: "https://icons.veryicon.com/png/o/miscellaneous/category-icon-set/category-56.png"
            },
            {
                name: "FOOD",
                img: "https://icons.veryicon.com/png/o/miscellaneous/category-icon-set/category-56.png"
            },
            {
                name: "TRAVEL",
                img: "https://icons.veryicon.com/png/o/miscellaneous/category-icon-set/category-56.png"
            }]
        return categories;
    }
    getFAQs() {
        let faqs = {
            safetyFaqs: [
                {
                    qes: 'Is it safe to go on the trail all alone?',
                    ans: "Hello Solo Traveller. Yes, it's absolutely safe to ride all alone. And also, our authorised Tour Leader will ensure certain etiquettes",
                },
                {
                    qes: 'Is it safe for girls & kids to do the trails alone?',
                    ans: "Yes, we do take adequate measures to ensure the safety for female riders. The kid must be 5'2'' to be able to ride the ebike comfortably.",
                },
                {
                    qes: 'Do I need to carry a first-aid kit?',
                    ans: 'No. If there is a need for a first-kit on the trail, the tour leader shall help you with the same.',
                },
                {
                    qes: 'How good is the mobile network while on trail?',
                    ans: 'There are many places on the trail where the network shall be available. Do not hesitate to ask tour leader to help you find the nearest network point on the trail.',
                },
                {
                    qes: 'What if my ebike gets punctured on tours?',
                    ans: 'Inform the tour leader. They shall assist you for the same. In case if you are taking self-guided tour reach to the nearest strong network zone and call on the following 02071177633.',
                },
                {
                    qes: 'Will the ebike move if the battery is drained while on tour?',
                    ans: 'Yes. Once the battery is drained, you can pedal your way to the rest of the journey just like any other regular bicycle.',
                },
                {
                    qes: 'What are the safety precautions I must take before I start the ride?',
                    ans: 'Wear your safety gears (helmet, Knee & Elbow Guards, etc) before you start the ride. ',
                },
                {
                    qes: 'What happens if my mobile gets switched off while on the trail?',
                    ans: 'We recommend you to charge your mobile phone using USB charging socket present on the mobile phone holder.',
                },
                {
                    qes: 'How safe it is to ride after the sunset?',
                    ans: 'Its perfectly safe to ride in the dark as the headlight present on the e-bike helps see the trail path clearly.',
                },
            ],
            paymentFaqs: [
                {
                    qes: 'What are the payment options available to book a tour?',
                    ans: 'Our payment gateway partner Razorpay has a range of payment methods. You can use your credit card, debit card, Wallet, etc. to book the tour.',
                },
                {
                    qes: 'Will I have to pay any charges if I cancel my booking?',
                    ans: 'No charges will be incurred if you cancel your booking within the specified time period. For shorter duration tours, a nominal cancellation fee may also be charged. Please refer to the cancellation policy for more details.',
                },
                {
                    qes: 'Can I pay offline as I am not confident to pay online?',
                    ans: "Offline or Online payments. We accept both the modes of payment. If you want to pay offline, it's super easy, you can find QR codes to pay at Strode Experiences kiosk. Once you pay at the kiosk, the partner (let's name them) shall provide you the coupon to unlock the e-bike.",
                },
            ],
            returnsFaqs: [
                {
                    qes: 'Is there any penalty on late return?',
                    ans: 'We offer a lag of 15 minutes from the tour end time. Afterward, double of the hourly charges will be payable by you. It is always better to extend your trip in advance by logging in to your account.',
                },
                {
                    qes: 'I am shortening my trip. How shall I get the refund?',
                    ans: 'There are no refunds on shortening the trip.',
                },
                {
                    qes: 'What is the process to return the ebike?',
                    ans: 'Process is pretty simple and straightforward. You have to return each and everything which you take up with you at the time of pick up. Those includes ebike, safety gears, water bottle, drawstring bag, etc.',
                },
                {
                    qes: 'How do I pay the penalty?',
                    ans: 'Penalty payment can be done online or else you can pay to the partner offline at drop location.',
                },
            ],
            toursFaqs: [
                {
                    qes: 'What is a Tour?',
                    ans: "Strode Experiences Tour is a way of navigating the city with a self-guided ebike. It's like having an all-knowing personal guide in the destination city, who will show you where to find the best hidden gems.",
                },
                {
                    qes: 'What is a Trail?',
                    ans: 'Strode Experiences Trails cover offbeat places that inspire locals and travelers alike. Destinations include things to do like historical & cultural tours, places to eat, family-friendly activities and more.',
                },
                {
                    qes: 'Can I take Car/Bicycle/Walk on the tour?',
                    ans: "No. You must have a Strode ebike to have sobriquet 'true experience' of the destination city. We at Strode Experiences believe when you're riding at 25 kmph you explore the destination more, you're neither too slow nor too fast.",
                },
                {
                    qes: 'What are Trail or Tour Highlights?',
                    ans: 'Trail highlights are specific locations along the route that we pick as memorable and worth stopping at. The audio/video guide allows you to take in some local knowledge and try out interesting activities that are maybe not necessarily available anywhere on the internet.',
                },
            ],
            bookingFaqs: [
                {
                    qes: 'How to book a tour?',
                    ans: "Just login to www.strodeexpereince.com, Select the Tour or a Trail, add to the Bucket List, Select the Pick-up Location and Time, and Checkout. Once your payment is processed, you get the complete details of the pickup location with the location link and contact number. You shall also get the contact details of your dedicated trip manager at the same time. Just collect your bicycle and ride off. It's super easy, isn't it?",
                },
                {
                    qes: 'How can I book a tour without seeing the Ebike physically?',
                    ans: 'We offer a 100% moneyback guarantee. Reach location and test drive your ebike. If there is any issue in the vehicle, raise a ticket and you shall be refunded 100% amount you have paid us. No questions asked in case there is mechanical & electrical fault in the ebike.',
                },
                {
                    qes: 'How shall I get the address to pick up the ebike?',
                    ans: "Once your booking is complete, you get an E-mail or SMS having the complete address and bike pick up point contact person's number. If you're booking through App, you will get the directions to reach the Bike pick up location. ",
                },
                {
                    qes: 'Which documents I need to provide you?',
                    ans: "Generally, any ID proof such as Aadhaar, Voter ID, Passport or a valid driving license. We don't accept color copies of documents in place of the original. For Foreign customers, it is Passport or International Driving License.",
                },
                {
                    qes: 'How should I book through e-bike screen?',
                    ans: "Well, it's super easy. Just tap on the screen, tap get started, pick your tour/trail, Add to Bucket List, click Pay Now. Voila! your Trail is booked.",
                },
                {
                    qes: 'I have already booked the tour. How to get started on the e-bike screen?',
                    ans: 'Welcome to Strode Experiences. Tap on already booked? on th e-bike screen. Enter the coupon code to unlock the tour. ',
                },
                {
                    qes: 'Is there any security deposit I need to make?',
                    ans: "No, you don't need to pay any security amount. In case if you're staying in any of the Hotel/Resort please share your booking details of the same with the Strode Partner at the time of bike pick up. ",
                },
                {
                    qes: "What if I don't get an ebike after booking?",
                    ans: "This is a rare instance as we always maintain real-time availability. There may be instances where ebikes, unfortunately, get into incidents. In such a case, we shall offer you an alternate ebike (depends on the availability of course). If an alternate ebike isn't available, we shall refund you 100% amount within 5 -6 working days.",
                },
                {
                    qes: 'Can I make the changes in the existing bookings?',
                    ans: 'Need to add a traveler, change the date, or make another adjustment? Not a problem! In most cases, you can change your booking up to 24 hours before your tour. However, please check the “Terms and Conditions” section for the detailed policy for your specific tour. Alternatively, you can reach out to us on our toll-free number 1800 2678 7633.',
                },
                {
                    qes: 'Is pick up & drop facility is available for the tours?',
                    ans: 'No. Our tours start from dedicated pick up points. You have to reach to the pick up points on your own.',
                },
                {
                    qes: 'Can I book it for multiple people?',
                    ans: 'Yes, you can choose to add multiple guests. Based on the number of pax on bookings, e-bikes shall be allotted to you.',
                },
                {
                    qes: 'Can you drop the ebike at the location of my choice?',
                    ans: "We are extremely sorry for that. Though we would like to offer delivery of the e-bikes, currently it's not being offered. You shall need to pick the e-bikes from our partner hotels/stations' location only.",
                },
                {
                    qes: 'What are the station open & timings?',
                    ans: "The station timings vary for different stations. We would recommend you to check individual tour details page FAQs to know the station's open & close timings",
                },
                {
                    qes: 'Can I ride these e-bikes?',
                    ans: "Yes. If you're 5 feet or taller, then absolutely! All you need know is how to bicycle.",
                },
                {
                    qes: 'What are e-bikes?',
                    ans: 'Just picture a regular bicycle, then add several electrical components to it like a motor, a battery, and a controller—all seamlessly integrated into the design.',
                },
                {
                    qes: 'Do these e-bikes require registration?',
                    ans: 'There is no need for registration of these e-bikes.',
                },
                {
                    qes: 'What are some of the must visit places in Jaipur?',
                    ans: 'When in Jaipur, do not miss out on Hawa Mahal, City Palace, Johari Bazaar and Amber Fort.',
                },
                {
                    qes: 'What type of climate can be expected here?',
                    ans: 'Jaipur’s climate ranges from post monsoon charm, the scorching heat of the sun to cooler and pleasant winters.',
                },
                {
                    qes: 'Is Lonavala & Khandala same?',
                    ans: 'Just 4.6 kms apart from each other, Lonavala and Khandala are home to dramatic landscapes, historical forts, ancient caves, charming sunset points, beautiful lakes and cascading waterfalls.',
                },
                {
                    qes: 'Why Lonavala & Khandala is known as The Jewel of the Western Ghats?',
                    ans: "Magical waterfalls, pristine hiking trails, breathtaking vantage points, ancient caves and historical forts - Lonavala & Khandala has something to offer to everyone which has earned them the sobriquet 'The Jewel of the Western Ghats'. ",
                },
                {
                    qes: 'How many days in advance should i book?',
                    ans: 'It is generally advised to book at least 2 weeks prior to the experiences.',
                },
            ],
            strodeBikesFaqs: [
                {
                    qes: 'What level of physical fitness is required to do the tours or trails on these e-bikes ?',
                    ans: "Physical fitness it is definitely a good to have, but these e-bikes are designed in such a manner that it doesn't require you put any extra effort while you're riding on them.",
                },
                {
                    qes: 'Do I need a driving license to ride these e-bikes?',
                    ans: 'PNope! The childhood nostalgia is back, but this time with a throttle. ',
                },
                {
                    qes: 'How much range this e-bike offers on a single charge?',
                    ans: "You really don't have to worry much about the range as the tours & trails designed by us are well thought out and it shall give you enough of juice to complete your tour. For your information, Strode e-bikes can give you a range of 80 km in a single charge.",
                },
                {
                    qes: 'I have a kid. How to take him/her with me on the tour?',
                    ans: 'Well, we love kids too! We have a special kid seat on these e-bikes wherein a kid can be a pillion rider on the e-bike.',
                },
                {
                    qes: 'Do I have to wear a helmet?',
                    ans: "Yes, it's Mandatory on ebike related activities.",
                },
                {
                    qes: 'Can I bring my own bike?',
                    ans: 'No, We provide the state of the art ebikes which will enhance your experiences.',
                },
                {
                    qes: 'What equipment is included?',
                    ans: 'We provide you with Strode ebikes, equipped with an infotainment system and we also provide you with helmets and water bottles.',
                },
                {
                    qes: 'What if my bike breaks down? Or I get a flat tire?',
                    ans: 'Our travel host will be with you and assist you with this.',
                },
                {
                    qes: 'Is the bike cost per day or for the duration of the tour?',
                    ans: 'The bike will be provided to you for the duration of the experience.',
                },
                {
                    qes: 'What accessories come with bike rentals?',
                    ans: 'We have an infotainment system attached to the bikes alongside helmets which are mandatory on these experiences.',
                },
                {
                    qes: 'Do I have to bring a helmet?',
                    ans: 'No a helmet will be provided to you as it is mandatory for all ebike related experiences.',
                },
                {
                    qes: 'do the bikes have headlights?',
                    ans: 'No a helmet will be provided to you as it is mandatory for all ebike related experiences.',
                },
                {
                    qes: 'do the bikes have an option for Bluetooth?',
                    ans: 'Yes.',
                },
                {
                    qes: 'Can i rent the bike for a couple of days?',
                    ans: 'Can i rent the bike for a couple of days?',
                },
                {
                    qes: 'If my bike stops working what do i do?',
                    ans: 'A new bike will be provided for you.',
                },
                {
                    qes: 'If the maps on the bike is not functioning what do i do?',
                    ans: 'Please let us know about such issues so that we can replace your bike.',
                },
                {
                    qes: 'Is there a medkit on the ebike?',
                    ans: 'Your travel host is equipped with a trusty medkit for health emergencies on the go.',
                },
                {
                    qes: 'What if the battery of the e-bike runs out mid experience?',
                    ans: 'All bikes will be fully charged before each experience and even if in the rare case of a battery discharge, you will still be able to pedal and get to your destination.',
                },
                {
                    qes: 'Do i have a backup battery with me during the experiences?',
                    ans: "No, we don't carry backup batteries on the experience.",
                },
            ],
            cancellationFaqs: [
                {
                    qes: 'Can I cancel my bookings?',
                    ans: "In most cases, you can cancel your booking for a full refund up to one day before your tour. However, please check the 'Terms and Conditions” on the website for the detailed cancellation policy for your specific tour.'",
                },
                {
                    qes: 'What if I want cancel the tour in the midst?',
                    ans: "You can't cancel the tour, once your tour starts.  You can definitely choose to return to the drop location at any point while on the tour, but you will not be given any refund or rearrangement",
                },
                {
                    qes: "What if I don't turn up on the start time of the tour?",
                    ans: 'If you do not show up at the start time of a booked tour and only get back to us later in the day or next day, then your booking will be considered cancelled. If you wish to reschedule your booking please reach out to us 24 hours before the tour start time.',
                },
            ],
            assetPartnersFaqs: [
                {
                    qes: 'Why with us?',
                    ans: [
                        {
                            heading:
                                'We at Strode Experiences provides following opportunities for our partners:',
                            points: [
                                { point: 'Assured business with our partner hotels ' },
                                { point: 'Timely payments' },
                                { point: 'Partner with only self-guided e-bike touring company' },
                                { point: 'Digital Marketing at no additional cost' },
                                { point: 'Subscription plans as per your convenience' },
                                { point: 'Detailed Dashboard' },
                                { point: 'Financial Assistance' },
                                { point: 'Call Support' },
                                { point: 'Asset Insurance' },
                            ],
                        },
                    ],
                },
                {
                    qes: 'What support would be given by Strode Experiences?',
                    ans: [
                        {
                            heading: '     ',
                            points: [
                                { point: 'Operational Support by leveraging our technology ' },
                                { point: 'Marketing support to conduct local activities' },
                                { point: 'Guidelines for managing the assets efficiently' },
                                { point: 'Training and Development Support' },
                                { point: 'Station Launch ' },
                                { point: 'Special products and offers on need' },
                                { point: 'Technological support' },
                            ],
                        },
                    ],
                },
                {
                    qes: 'Do I need relevant experience in the travel industry?',
                    ans: 'Experience in travel industry is preferred but is not a showstopper for people who have passion for the travel industry, adequate business acumen, leadership traits, proven management capabilities and an ability to adapt to a constantly changing and evolving travel industry is preferred.',
                },
                {
                    qes: 'Will I get recruitment and training assistance?',
                    ans: 'Strode Experiences assists its partners with guidelines on recruitment of suitable staff. Training is an integral part of Strode Experiences franchisee business and is done through partner training programs and regular process & product training workshops.',
                },
                {
                    qes: 'Is location an important selection criterion?',
                    ans: "Well, if you want to operate outside our partner hotel's location then, certainly yes, location is an important selection criterion and the station should ideally be at a high footfall area, with adequate infrastructure (e-bike parking space, a good frontage and signage space.)",
                },
                {
                    qes: 'What is the minimum invest I have to make?',
                    ans: 'We usually start by asking the person what their budget is and then we go ahead and furnish them with options that are within that range. You can reach out to us on our toll free number 1800 5678 7633 for further details.',
                },
                {
                    qes: 'Do you provide financing options?',
                    ans: 'Yes, we provide various financing options that match your payment needs. If you need further details on same do reach out to us on our toll free number 1800 5678 7633.',
                },
                {
                    qes: 'How much commission do I have to pay?',
                    ans: "Our commission is a percentage of the tour price. Depending upon the partner's tier category the commission varies.",
                },
                {
                    qes: 'What are different categories of partner?',
                    ans: [
                        {
                            heading: '     ',
                            points: [
                                {
                                    point: '20% Commission - ',
                                },
                                {
                                    point: '30% Commission - ',
                                },
                                {
                                    point: '40% Commission - ',
                                },
                            ],
                        },
                    ],
                },
            ],
            lonavlaFaqs: [
                {
                    qes: 'What is the best time to visit Lonavala?',
                    ans: 'The best time to visit Lonavala is between the months of October and May. Avoid visiting Lonavala in between the months of June and September due to heavy rainfall. The summertime is mildly from April to mid of June when the weather is humid & hot.',
                },
                {
                    qes: 'Is Lonavala child friendly place?',
                    ans: "Yes it's calm and full of nature it's a good place for children to spend time with there parents on a road trip and in resorts as well. If your kids are of age like 15 to 18 they will love to cycling on road.",
                },
                {
                    qes: 'How to reach Lonavala?',
                    ans: [
                        {
                            point:
                                'By road: You can either board a taxi or a bus or you can take your own car from Mumbai to reach Lonavala via Bengaluru - Mumbai Hwy/Mumbai Hwy/Mumbai - Pandharpur Rd/Mumbai - Pune Hwy.',
                        },
                        {
                            point:
                                'By air: Catch a flight from any of the domestic Indian airports and reach the Lohegaon airport in Pune. This airport is closest to Lonavala and you can reach the destination in no time.',
                        },
                    ],
                },
                {
                    qes: 'Why is Lonavala famous?',
                    ans: 'Lonavala is famous for remarkable caves, serene lakes, stunning waterfalls, lush green valleys and several natural wonders. It is also very famous for the historical sites and religious attractions. However, if you are a food enthusiast then you cannot miss shopping for the nutty Chikki and choco-walnut fudge here that are one of the most popular items of Lonavala.',
                },
                {
                    qes: 'What is the famous food of Lonavala?',
                    ans: 'Some of the must-try dishes while touring the places in Lonavala are Maharashtrian Usal (curried sprouted lentils and beans), Chola bhatura, Butter chicken and Chocolate fudge. Vegetarians can enjoy a traditional, mouth-watering Maharashtrian Thali. Your tour of places to visit in Lonavala is incomplete without indulging in the lip-smacking and spicy Vada pav with hot masala chai.',
                },
                {
                    qes: 'What are the languages spoken in Lonavala?',
                    ans: 'Hindi, Marathi & English',
                },
                {
                    qes: 'Which are the famous caves of Lonavala?',
                    ans: "Majorly there are two caves Karla Caves & Bhaja Caves. Because of their shear massiveness it has earned a sobriquet 'City of Caves'",
                },
                {
                    qes: 'How safe is Lonavala?',
                    ans: 'This city has a low crime rate and therefore considered relatively safe. However, petty crimes like pick pocketing or bag snatching are common. It’s advisable to always be cautious of your surroundings.',
                },
                {
                    qes: 'How the geographical set up of Lonavala & Khandala looks like?',
                    ans: 'Lonavala and Khandala are found in the Sahyadri ranges that distinguish the Deccan Plateau from the Konkan coast. Located 622 metres (2,041 feet) above sea level, the towns are spread over an area of about 38 square kilometres.',
                },
                {
                    qes: 'What are the things to see and do in Lonavala?',
                    ans: "Things to see and do in Lonavala vary from great outdoor activities to splendid scenic sightseeing. Among the places to visit in Lonavala, nature lovers can head to Kune Waterfalls and Khandala ghat, thrill-seekers can visit Nagphani (Duke’s nose) which apart from the best sunset view also offers adventurous activities like zip-lining, rappelling, camping and more. History lovers can adore the charming Karla and Bhaja caves. It's a perfect place-to-be for every traveler.",
                },
                {
                    qes: 'What are the things to shop in Lonavala?',
                    ans: 'Take the beautiful memories of Lonavala back home in the form of souvenirs. The classic ‘Lonavala Chikki’, a sweet candy made with peanuts, melted jaggery and ghee, is the most popular souvenir. Handcrafted mementoes, Maratha souvenirs and classic keychains are some of the other items that you can pick from places to visit in Lonavala.',
                },
            ],
            udaipurFaqs: [
                {
                    qes: 'What is the best time to visit Udaipur?',
                    ans: 'Udaipur receive travellers thought-out the year. With many lakes around, Udaipur remains pleasant round the year. However, best time to visit Udaipur is between September to April.',
                },
                {
                    qes: 'What type of tours are being offered by Strode Experiences in Udaipur?',
                    ans: 'There are multiple tours being offered by Strode Experiences that includes Heritage Tours, Garden Tours, Culture Tours, Museum Tours to name a few. ',
                },
                {
                    qes: 'What are some of the must visit places in Udaipur?',
                    ans: 'When in Udaipur, do not miss out on Jag Mandir (Shah Jahan took inspiration to make Taj Mahal), Kumbalgarh (Second longest wall in the world after Great Wall of China), Tree House (featured in the Limca Book of World Records) and Jaisamand Lake (second largest artificial freshwater lake in Asia). ',
                },
                {
                    qes: 'What type of climate can be expected here?',
                    ans: 'Udaipur’s climate ranges from post monsoon charm, the scorching heat of the sun to cooler and pleasant winters.',
                },
                {
                    qes: 'Where exactly is Udaipur located?',
                    ans: 'Udaipur lies in South-Rajasthan and has proximity with Gujarat.',
                },
                {
                    qes: 'What type of clothes to wear in Udaipur?',
                    ans: 'Depends on the time of your visit. Summer - light clothes, Monsoon - wind cheater jacket or raincoats, and Winter - thermals or warm jackets',
                },
                {
                    qes: 'Is Udaipur safe?',
                    ans: 'Yes, the city is rather safe. However, we suggest you to be careful while meandering along the busy and bustling bazaars of Udaipur. In case of any emergency feel free to reach out to us on our toll free no 1800 5678 7633.',
                },
                {
                    qes: 'Which are the interesting places near Udaipur?',
                    ans: 'Apart from sightseeing tours in Udaipur, it is suggested to visit places near Udaipur like Kumbhalgarh Fort, Ranakpur Jain temples, Jaisamand lake.',
                },
                {
                    qes: 'How much area does Udaipur cover?',
                    ans: 'Udaipur covers an area of about 37 sq km',
                },
                {
                    qes: 'How does one greet the locals?',
                    ans: 'Khamma Ghani Sa is usually said to wish Good Morning or hello in Udaipur. Whereas goodbye is Padhaaro Sa.',
                },
                {
                    qes: 'What is famous for shopping in Udaipur?',
                    ans: 'Everything from ethnic wear, traditional souvenirs, jewelry, leather bags & accessories, to handicrafts can be bought in Udaipur. Whether you are in a mall in Udaipur city or strolling through a bustling market, you’ll find numerous things to take home a piece of this vibrant culture.',
                },
                {
                    qes: 'What is the unique antiquity of Udaipur?',
                    ans: 'The history of Udaipur is of considerable antiquity in form AHAR civilisation that flourished in the vicinity of Berach River. The chalco-lithic Ahar-Banas culture lasted from 3000 BCE to 1500 BCE. More than 90 sites of the culture have been identified till date, out of which, Gilund, Ahar, Ojiyana and Balathal  are prominent sites.',
                },
                {
                    qes: 'What is the famous food of Udaipur?',
                    ans: 'Kachoris are considered to be quite popular in the city of Udaipur. They are prepared with quality, keeping all the savory flavors in mind. Different varieties are prepared using pulses, curd, or onions. Other traditional cuisine includes Dal Bati Churma, Ker Sangri, Gatte Ki Sabji, Lal Mans (heaven for meat lovers). ',
                },
                {
                    qes: 'What is so unique about Mewar dynasty of Udaipur?',
                    ans: "The Mewar dynasty is the world's oldest surviving dynasty with a time span of 1,500 years and 26 generations and has outlived eight centuries of foreign domination. Extremely possessive about their culture, tradition and honour, the Sisodias have played an important role in medieval Indian history as tireless upholders of Hindu traditions.",
                },
            ],
            jaipurFaqs: [
                {
                    qes: 'What is the best time to visit Jaipur?',
                    ans: 'The best time to visit Jaipur is between the months of October to March, with occasional showers bathing the city’s streets accompanied with snug weather.',
                },
                {
                    qes: 'What type of tours are being offered by Strode Experiences?',
                    ans: 'There are multiple tours being offered by Strode Experiences that includes Heritage Tours, Market Tours, Food Tours, Water Walks, Museum Tours to name a few.',
                },
                {
                    qes: 'Where exactly is Jaipur located?',
                    ans: 'Jaipur is in the state of Rajasthan, towards the north-western part of India. Rajasthan shares proximity with Gujarat, Madhya Pradesh, Uttar Pradesh, Punjab, and Haryana.',
                },
                {
                    qes: 'What is the shopping scene in Jaipur?',
                    ans: 'You will plenty of souvenirs to take back home with you from the bustling bazaars of Jaipur. Be it handmade items or old artefacts.',
                },
                {
                    qes: 'Is Jaipur safe?',
                    ans: 'Yes, the city is rather safe. However, we suggest you to be careful while meandering along the busy and bustling bazaars of Jaipur.',
                },
                {
                    qes: 'Which are the forts to be visited in Jaipur?',
                    ans: 'Primarily within Jaipur there are three forts: Amber, Nahargarh and Jaigarh. If you plan to travel a bit from Jaipur for about 100-150 km you shall be able to cover 7 more forts Nawalgarh Fort, Mandawa Fort, Laxmangarh Fort, Kesroli hill fort, Taragarh Fort, Bhangarh Fort and Bala Quila Fort. ',
                },
                {
                    qes: 'How much area does Jaipur cover?',
                    ans: 'Jaipur covers approximately an area of 11117.8 kilometers, making it 4.11% of the area of Rajasthan.',
                },
                {
                    qes: 'How does one greet the locals?',
                    ans: 'Khamma Ghani Sa is usually said to wish Good Morning or hello in Jaipur. Whereas goodbye is Padhaaro Sa.',
                },
                {
                    qes: 'What is Jaipur famous for ?',
                    ans: 'Jaipur is famous for its forts, arts and crafts, jewellery, handicrafts, gems, bangles, pottery, carpets, textiles, leather and metal products.',
                },
                {
                    qes: 'What is the historical and cultural importance of Jaipur?',
                    ans: 'Jaipur was built following the principles of Shilpa & Vastu Shastra. It is rich in its cultural and architectural beauty, which can be traced in the various historical and aesthetic places that reside in the city.',
                },
                {
                    qes: 'What are the best tips to visit Jaipur ?',
                    ans: [
                        {
                            point:
                                'Must have Kachauri in jaipur.  Ghevar is another must have sweet. ',
                        },
                        {
                            point:
                                'Walk in the bylanes of jaipur and get a feel of history. Lot has happened there over the last 300 years or so. ',
                        },
                        {
                            point:
                                'Jhalana is a safari area on the outskirts famous for leopard sightings.  ',
                        },
                        {
                            point:
                                'Fun fact: Jaipur has been named after Rana Jai Singh 2, an erstwhile King of Amer  ',
                        },
                    ],
                },
                {
                    qes: 'What is so unique about Jantar Mantar?',
                    ans: "Jantar Mantar is one of the kind experiences it's the largest stone astronomical observatory in the world. This UNESCO world heritage site is a popular place to visit in Jaipur for all especially the kids. It gives a peek into ancient times calculations and architectural accuracy.",
                },
                {
                    qes: 'What is so unique about Jantar Mantar?',
                    ans: "Jantar Mantar is one of the kind experiences it's the largest stone astronomical observatory in the world. This UNESCO world heritage site is a popular place to visit in Jaipur for all especially the kids. It gives a peek into ancient times calculations and architectural accuracy.",
                },
            ]
        }
        return faqs;
    }
    getJobPosition() {
        let position_List = [
            {
                name: 'Manager -  Strategic Partnerships (Travel) <br class="d-block d-md-none"> - 5+ YRS WORK-EX ',
                address: 'Pune,  India',
                class: 'position-1',
                btnclass: 'orange-btn'
            },
            {
                name: 'City Lead - Travel Operations <br class="d-block d-md-none"> - 5+ YRS WORK -EX',
                address: 'Udaipur, India',
                class: 'position-2',
                btnclass: 'pink-btn'
            },
            {
                name: 'Travel Experience Designer/ Curator (Remote) <br class="d-block d-md-none"> - 5+ YRS WORK -EX',
                address: '',
                class: 'position-3',
                btnclass: 'violet-btn'
            },
            {
                name: 'Travel Content (Research + Writing) (Remote)<br class="d-block d-md-none"> - 3+ YRS WORK -EX',
                address: ``,
                class: 'position-4',
                btnclass: 'teelgreen-btn'
            }
        ]
        return position_List;
    }
    getJobPositionList() {
        let positions = [
            { name: 'Manager - Strategic Partnerships (Travel)' },
            { name: 'City Lead - Travel Operations' },
            { name: 'Travel Experience Designer/ Curator' },
            { name: 'Travel Content (Research + Writing)' },
        ];
        return positions;
    }
    getOTSCarousel() {
        let carousel = [
            {
                heading: "DON'T JUST GO THERE, LIVE THERE!",
                desc: 'We have designed some of the more niche ways to experience the world. <br>  It’s not just about where you’re going, but things you do when you get there.',
            },
            {
                heading: "DON'T JUST DO IT, FEEL IT!",
                desc: 'Be it an adventure or some rare stuff happening at any edge of that destination. <br> Find it, Try it, Take it.',
            },
            {
                heading: "DON'T JUST SEE IT, KNOW IT!",
                desc: 'These are unique ways to learn about the  places you   go to on your travel. <br> Exclusively created by us, for you.',
            },
        ];
        return carousel;
    }
    getWhatWeDoElements() {
        let element = [
            {
                src: `<div class="text-center position-relative">
      <img loading="lazy"src="https://d3ctzkcim3mheb.cloudfront.net/strodeexperiences-live-media/what-we-do/elements/element-1.svg" class="mw-110" alt="">
      <img loading="lazy"src="https://d3ctzkcim3mheb.cloudfront.net/strodeexperiences-live-media/what-we-do/elements/element-24.svg"
          class="position-absolute small-element" alt="">
      <p class="text-center py-3 fsize-18 TTRegular-font my-md-4">Ride in an open world <br class='mob-display-none'>
          & not boxed cars</p>
      </div>`,
            },
            {
                src: `<div class="position-relative text-center">
      <img loading="lazy"src="https://d3ctzkcim3mheb.cloudfront.net/strodeexperiences-live-media/what-we-do/elements/element-6.svg" class="mw-110" alt="">
      <p class="text-center py-3 fsize-18 TTRegular-font my-md-4">Blend into culture, <br class='mob-display-none'>
          mix with people</p>
      </div>`,
            },
            {
                src: `<div class="position-relative text-center ">
      <img loading="lazy"src="https://d3ctzkcim3mheb.cloudfront.net/strodeexperiences-live-media/what-we-do/elements/element-10.svg" class="mw-120" alt="">
      <img loading="lazy"src="https://d3ctzkcim3mheb.cloudfront.net/strodeexperiences-live-media/what-we-do/elements/element-11.svg"
          class="position-absolute small-left-element" alt="">
      <p class="text-center py-2 fsize-18 TTRegular-font my-md-4">No noise e-bikes <br class='mob-display-none'>
          to get lost </p>
     </div>`,
            },
            {
                src: `<div class="position-relative text-center">
      <img loading="lazy"src="https://d3ctzkcim3mheb.cloudfront.net/strodeexperiences-live-media/what-we-do/elements/element-3.svg" class="mw-110" alt="">
      <img loading="lazy"src="https://d3ctzkcim3mheb.cloudfront.net/strodeexperiences-live-media/what-we-do/elements/element-2.svg"
          class="position-absolute cycle-element" alt="">
      <p class="text-center py-3 fsize-18 TTRegular-font my-md-4">Start & stop <br class='mob-display-none'>
          anywhere, anytime</p>
     </div>`,
            },
            {
                src: ` <div class="text-center">
      <img loading="lazy"src="https://d3ctzkcim3mheb.cloudfront.net/strodeexperiences-live-media/what-we-do/elements/element-7.svg" class="mw-110" alt="">
      <p class="text-center py-3 fsize-18 TTRegular-font my-md-4">Not just clicks <br class='mob-display-none'>
          but memories</p>
     </div>`,
            },
            {
                src: ` <div class="text-center position-relative">
      <img loading="lazy"src="https://d3ctzkcim3mheb.cloudfront.net/strodeexperiences-live-media/what-we-do/elements/element-12.svg" class="mw-110" alt="">
      <img loading="lazy"src="https://d3ctzkcim3mheb.cloudfront.net/strodeexperiences-live-media/what-we-do/elements/element-13.svg"
          class="position-absolute cycle1-element" alt="">
      <p class="text-center py-3 fsize-18 TTRegular-font my-md-4">Observe more <br class='mob-display-none'>
          when you ride slow</p>
      </div>`,
            },
            {
                src: `  <div class="text-center position-relative ">
      <img loading="lazy"src="https://d3ctzkcim3mheb.cloudfront.net/strodeexperiences-live-media/what-we-do/elements/element-5.svg" class="mw-110" alt="">
      <img loading="lazy"src="https://d3ctzkcim3mheb.cloudfront.net/strodeexperiences-live-media/what-we-do/elements/element-4.svg"
          class="position-absolute direction-element" alt="">
      <p class="text-center py-3 fsize-18 TTRegular-font my-md-4">Explore <br class='mob-display-none'>
          your time, your way</p>
      </div>`,
            },
            {
                src: `<div class="text-center">
      <img loading="lazy"src="https://d3ctzkcim3mheb.cloudfront.net/strodeexperiences-live-media/what-we-do/elements/element-8.svg" class="mw-110" alt="">
      <p class="text-center py-3 fsize-18 TTRegular-font my-md-4">Go for meaningful <br class='mob-display-none'>
          routes, not fastest</p>
      </div>`,
            },
            {
                src: `<div class="text-center position-relative pt-4 pt-md-0">
      <img loading="lazy"src="https://d3ctzkcim3mheb.cloudfront.net/strodeexperiences-live-media/what-we-do/elements/element-14.svg" class="mw-80" alt="">
      <p class="text-center py-3 fsize-18 TTRegular-font my-md-5">Handpicked places <br class='mob-display-none'>
          to eat, shop & chill</p>
      </div>`,
            },
        ];
        return element;
    }
    getFourPillars() {
        let pillers = [
            {
                title: "GUIDED",
                desc: "Upgrade: Our ebikes now feature personal guides, <br> enhancing expeditions with local insights, navigation,<br> and entertainment.Explore with a companion on two wheels!",
                img: "",
                video: "https://video.wixstatic.com/video/4a6ec1_c0651a0f0c4146849111d001b21d4739/1080p/mp4/file.mp4",

            },
            {
                title: "TRAVELLER'S EBIKE",
                desc: "Our bikes are designed to offer comfort, safety & <br> convenience on par with cars. Now swiftly switch to journeys <br> that are sustainable in every sense.",
                img: "",
                video: "https://video.wixstatic.com/video/4a6ec1_b874917a44e74f10a9f6066ffc935ea1/1080p/mp4/file.mp4"
            },
            {
                title: "PEOPLE",
                desc: "You can choose to explore on your own or move around <br> with our local hosts, who can offer special knowledge,<br> unique skills, and share their perspectives on every trip.",
                img: "",
                video: "https://video.wixstatic.com/video/4a6ec1_4866e650c0364c02b9ff0d675afbc7b4/1080p/mp4/file.mp4"
            },
            {
                title: "PLACES",
                desc: "You spend hours researching about things you'll see & <br> do for your next trip but still miss on some that you deserved.<br> No more now, we bring to you a selection of surprising places.",
                img: "",
                video: "https://video.wixstatic.com/video/4a6ec1_b874917a44e74f10a9f6066ffc935ea1/1080p/mp4/file.mp4"
            },
        ]
        return pillers;
    }
    getTourCategories() {
        let categories = [
            "LocalLovers",
            "NightNomads",
            "Historians",
            "Adventurers",
            "ArtAdmirers"
        ]
        return categories;
    }
    getMarkerColors() {
        return ['#8bc34a', '#ebb014', '#ff9800', '#f44336', '#ea4b6c', '#89c7cbd9', '#034c52', '#015c63', '#d68936', '#022f40', '#f9ad87'];
    }
}
