<app-loader [showSpinner]="showLoader"></app-loader>
<div class="overflow-hidden vh-100 bg-black multi-layer">
    <div class="video-container layer1">
        <div>
            <!-- <iframe width="100vw" id="iframe"
                    src="https://www.youtube.com/embed/9ZQFKIazprE?vq=hd1080&amp;&autoplay=1&mute=1&loop=1&controls=0&modestbranding=1&showinfo=0&fs=0&playlist=9ZQFKIazprE"
                    frameborder="0" class="video" id="youtube"></iframe> -->
            <!-- <iframe width="100vw" [src]="youtubeUrl| safe" frameborder="0" class="video" id="youtube"></iframe> -->
            <video autoplay="true" #headerVideo muted class="video d-none d-md-block" webkit-playsinline playsinline
                loop="true" [src]="VideosUrl[0]"></video>
            <video autoplay="true" #headerVideo muted class="mobileVideo d-md-none d-block" webkit-playsinline
                playsinline loop="true" [src]="VideosUrl[1]"></video>
        </div>
    </div>
    <div class="layer2"> </div>
</div>
<div class="container-fluid outerbox">
    <div class="row justify-content-center">
        <div class="col-lg-12 outerbox shadow text-white p-0">
            <div class="subbox textfont">
                <span class="TTRegular-font title text-white">Hey <span class="text-uppercase "><b
                            class="text-white">{{bookingConfirmObj.bookingSuccessResp?.guests[0]?.fname}}!</b></span></span>
                <p class="titlefont title text-white">Your booking is successfully confirmed.</p>
                <div class="col-lg-7 mt-md-5 ">
                    <div class="row  switchbox m-0">
                        <div class="col-sm-5 col-6 px-4 px-md-5 py-md-2 py-3 box1 " (click)="switchbox1()"
                            [ngClass]="switchOne?'lightswitch':'blackswitch'">
                            <h5 class="pt-2 TTRegular-font">Booking Details</h5>
                        </div>
                        <!-- <div class="col-sm-5 col-6 px-4 ps-md-4 py-md-2 py-3 box2" (click)="switchbox2()"
                            [ngClass]="switchTwo?'lightswitch':'blackswitch'">
                            <h5 class="pt-2 ms-md-3 TTRegular-font">Contact Information</h5>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-12 pt-4 pt-md-0  bdetail text-dark    ps-md-5 px-md-4 px-2 paddingtop"
                    *ngIf="switchOne">
                    <!-- <h5>Tour Overview</h5> -->
                    <div class="row py-md-2  ps-3 ps-md-0">
                        <div class="col-lg-2 col-6">
                            <h5 class="textnavy TTRegular-font"><b> Booking ID</b><br>{{bookingConfirmObj.booking_id}}
                            </h5>
                        </div>
                        <div class="col-lg-2 col-6">
                            <h5 class="TTRegular-font"><b>No. of Guests</b><br>
                                {{bookingConfirmObj.bookingSuccessResp['noof_guest']}} </h5>
                        </div>
                        <div class="col-lg-2 col-6">
                            <h5 class="TTRegular-font"><b>Payment Type</b><br>
                                {{bookingConfirmObj.bookingSuccessResp['payment_log'][0]?.methode | titlecase}} </h5>
                        </div>
                        <div class="col-lg-2 col-6">
                            <h5 class="TTRegular-font"><b>Amount Paid</b><br>&#8377;
                                {{bookingConfirmObj.bookingSuccessResp['amount_paid'] | number}}</h5>
                        </div>
                        <div class="col-lg-2 col-6 d-none d-md-block">
                            <h5 class="TTRegular-font"><b>Status</b><br> <span> Confirmed</span>
                            </h5>
                        </div>
                        <div class="col-lg-2 col-6 d-none d-md-block">
                            <h5 class="TTRegular-font"><b> Tour
                                    Name</b><br>{{bookingConfirmObj.bookingSuccessResp['tour']?.name |titlecase}} </h5>
                        </div>
                    </div>
                    <div class="row  pt-md-4 tour ps-3 ps-md-0 TTRegular-font">
                        <div class="col-lg-2 col-6 d-md-none">
                            <h5><b> Tour Name</b><br>{{bookingConfirmObj.bookingSuccessResp['tour']?.name |titlecase}}
                            </h5>
                        </div>
                        <div class="col-lg-2 col-6">
                            <h5><b>Station Name</b><br> {{bookingConfirmObj.bookingSuccessResp['station']?.title}} </h5>
                        </div>
                        <div class="col-lg-2 col-6">
                            <h5><b>Payment Date</b><br>{{bookingConfirmObj.bookingSuccessResp.payment_log[0]?.date
                                |date}} <br>
                                <span class="time"> {{bookingConfirmObj.bookingSuccessResp.payment_log[0]?.date
                                    |date:'mediumTime'}}</span>
                            </h5>
                        </div>
                        <div class="col-lg-3 d-none d-md-block col-6 ">
                            <h5 class="fsize-18 textblack  ps-3 ps-md-0"><b>Payment ID </b> <br>
                                {{bookingConfirmObj.bookingSuccessResp['payment_log'][0]?.payment_id}} </h5>
                        </div>
                        <div class="col-lg-2 col-6 d-block d-md-none">
                            <h5><b>Status</b><br> <span> Confirmed</span> </h5>
                        </div>
                    </div>
                    <div class="row py-md-2 justify-content-between TTRegular-font">
                        <div class="col-lg-2  col-7 d-block d-md-none">
                            <h5 class="fsize-18 textblack  ps-3 ps-md-0 "><b>Payment ID </b>
                                {{bookingConfirmObj.bookingSuccessResp['payment_log'][0]?.payment_id}} </h5>
                        </div>
                        <div class="col-lg-2 col-6 d-none d-md-block"></div>
                        <div class="col-lg-4 btnbox">
                            <button class="border-0 explorebtn  pink-orangebtn   textfont" (click)="exploreMore()">
                                <span class="btnshadow text-white">Explore More Trips <i
                                        class="fa fa-arrow-right ps-2 text-white"></i></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 bg-white bdetail text-dark pt-4 pt-md-0 px-md-5 px-4 paddingtop TTRegular-font"
                    *ngIf="switchTwo">
                    <div class="row contact row justify-content-end">
                        <div class="col-lg-3 col-5">
                            <h5><b>Station Name</b> <br>{{bookingConfirmObj.bookingSuccessResp['station']?.title}} </h5>
                        </div>
                        <div class="col-lg-2 col-7">
                            <h5><b>Mobile
                                    Number</b><br>{{bookingConfirmObj.bookingSuccessResp.station.handled_by_partner?.mobile_number}}
                            </h5>
                        </div>
                        <div class="col-lg-2 col-5">
                            <h5><b>First
                                    Name</b><br>{{bookingConfirmObj.bookingSuccessResp.station.handled_by_partner?.first_name}}
                            </h5>
                        </div>
                        <div class="col-lg-5 col-7 ps-md-5">
                            <!-- <h5><b>Email
                                    ID</b><br>{{bookingConfirmObj.bookingSuccessResp.station.handled_by_partner?.primary_email_address}}
                            </h5> -->
                            <h5><b>Email ID</b><br><a
                                    href="mailto:contact@strodeexperiences@gmail.com">contact&#64;strodeexperiences.com</a>
                            </h5>
                        </div>
                        <div class="col-lg-4 btnbox">
                            <button class="border-0 explorebtn   pink-orangebtn   TTRegular-font"
                                (click)="exploreMore()">
                                <span class="btnshadow text-white">Explore More Trips <i
                                        class="fa fa-arrow-right ps-2 text-white"></i></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast></p-toast>
<!-- Event snippet for Paid SE - AI conversion page -->
<script>
    gtag('event', 'conversion', {
        'send_to': 'AW-11334778634/_bzoCJHWzfUYEIr-65wq',
        'transaction_id': ''
    });
</script>