import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'linkPrefix'
})
export class LinkPrefixPipe implements PipeTransform {
    IMG_BASE_URL = "https://d3ctzkcim3mheb.cloudfront.net/strodeexperiences-live-media";
    transform(value: string): string {
        return this.IMG_BASE_URL + value;
    }
}
