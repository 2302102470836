<div class="row footer justify-content-center  p-3">
        <div class="col-lg-12 text-center">
                <span (click)="navigate('/')" class="text-decoration-none">
                        <img loading="lazy" [src]="'/element/logo.png' | linkPrefix" class="logo my-3 cursor-pointer"
                                alt="">
                        <h3 class="TTRegular-font text-white fsize-18">Strode Experiences</h3>
                </span>
                <h3 class="futura-book-font fsize-16 mt-4  text-dark"><i class="fa fa-phone text-white"></i><span
                                href="tel:+1800 2678 763" class="text-decoration-none text-white"> +91 1800 2678
                                763</span>
                </h3>
        </div>
        <div class="col-lg-8 my-3 ps-4 ps-md-0 footer-container">
                <div class="row py-1 px-md-3  justify-content-center ">
                        <div class="col-lg-3 col-6 ps-4">
                                <br>
                                <h3 class="TTRegular-font textTealGreen fsize-18 ">What is Strode</h3>
                                <div class="my-4 text-start ">
                                        <ul>
                                                <li class="my-2"><span (click)="navigate('/')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">Home</span>
                                                </li>
                                                <li class="my-2"><span (click)="navigate('/what-is-strode/how-we-work')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">How
                                                                We Work</span>
                                                </li>
                                                <li class="my-2"><span
                                                                (click)="navigate('/what-is-strode/self-guided-trips')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">Self
                                                                Guided Trips</span></li>
                                                <li class="my-2"><span (click)="navigate('/what-is-strode/what-we-do')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">What
                                                                We Do</span></li>
                                                <li class="my-2"><span
                                                                (click)="navigate('/what-is-strode/sustainability')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">Sustainability</span>
                                                </li>
                                        </ul>
                                </div>
                        </div>
                        <div class="col-lg-3 col-6 ps-4">
                                <br>
                                <h3 class="TTRegular-font textTealGreen fsize-18 ">Destinations</h3>
                                <div class="my-4 text-start ">
                                        <ul>
                                                <li class="my-2"><span (click)="redirectToCity('lonavala')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">
                                                                Lonavala, IN</span>
                                                </li>
                                                <li class="my-2"><span (click)="redirectToCity('jaipur')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">
                                                                Jaipur, IN</span>
                                                </li>
                                                <li class="my-2"><span (click)="redirectToCity('udaipur')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">
                                                                Udaipur, IN</span></li>
                                                <li class="my-2"><span (click)="redirectToCity('amsterdam')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">
                                                                Amsterdam, NL</span></li>
                                        </ul>
                                </div>
                        </div>
                        <div class="col-lg-3  col-6 ps-4">
                                <br>
                                <h3 class="TTRegular-font textTealGreen fsize-18 ">All The Info</h3>
                                <div class="my-4 text-start ">
                                        <ul>
                                                <li class="my-2"><span
                                                                (click)="navigate('/all-the-info/our-travel-style')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">Our
                                                                Travel Styles</span></li>
                                                <li class="my-2"><span (click)="navigate('/all-the-info/local-hosts')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">
                                                                Local Hosts</span>
                                                </li>
                                                <li class="my-2"><span
                                                                (click)="navigate('/all-the-info/hospitality-partners')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">
                                                                Hospitality Partners</span></li>
                                                <li class="my-2"><span (click)="navigate('/all-the-info/all-faqs')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">All
                                                                FAQs</span></li>
                                                <li class="my-2"><span (click)="navigate('/all-the-info/join-our-team')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">Join
                                                                Our Team</span>
                                                </li>
                                        </ul>
                                </div>
                        </div>
                        <div class="col-lg-2 col-6 ps-4">
                                <br>
                                <h3 class="TTRegular-font textTealGreen fsize-18 ">Helpful Links</h3>
                                <div class="my-4 text-start ">
                                        <ul>
                                                <li class="my-2"><span (click)="navigate('/other/contact-us')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">Contact
                                                                Us</span></li>
                                                <li class="my-2"><span (click)="navigate('/policy/booking-policy')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">Booking
                                                                Policy</span></li>
                                                <li class="my-2"><span (click)="navigate('/policy/privacy-policy')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">Privacy
                                                                Policy</span>
                                                </li>
                                                <li class="my-2"><span (click)="navigate('/policy/cookie-policy')"
                                                                class="text-white text-decoration-none TTRegular-font  fsize-16">Cookie
                                                                Policy</span></li>
                                                <li class="my-2"><span (click)="navigate('/policy/terms-of-use')"
                                                                class="text-white text-decoration-none TTRegular-font   fsize-16">Terms
                                                                of Use</span></li>
                                        </ul>
                                </div>
                        </div>
                </div>
        </div>
        <div class="col-lg-12 p-0 text-center">
                <div class="social-media text-center pb-3 bottom-line">
                        <span (click)="openSocialLink('https://www.facebook.com/strodeexperience?mibextid=eBUYbo')">
                                <i class="fa fa-facebook" aria-hidden="true"></i>
                        </span>
                        <span (click)="openSocialLink('https://www.twitter.com/strodebike/')">
                                <img loading="lazy" [src]="'/element/twitter.svg' | linkPrefix" alt="">
                        </span>
                        <span (click)="openSocialLink('https://www.linkedin.com/company/strodebike/')">
                                <i class="fa fa-linkedin" aria-hidden="true"></i>
                        </span>
                        <span (click)="openSocialLink('https://www.instagram.com/strodeexperiences/')">
                                <i class="fa fa-instagram" aria-hidden="true"></i>
                        </span>
                        <span (click)="openSocialLink('https://www.threads.net/@strodeexperiences')">
                                <img loading="lazy" [src]="'/element/thread.svg' | linkPrefix" alt="">
                        </span>
                        <p class="TTRegular-font fsize-14 my-3 text-white d-none d-md-block">Moving Strodesters Inc, All
                                rights reserved. </p>
                </div>
                <p class="TTRegular-font fsize-14 my-3 text-white d-md-none d-block">Moving Strodesters Inc, All rights
                        reserved. </p>
        </div>
</div>