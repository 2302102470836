import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit(): void { }
  redirectToCity(city) {
    this.router.navigateByUrl(`/things-to-do-in-${city}`);
  }
  navigate(url) {
    this.router.navigate([url]);
  }
  openSocialLink(url) {
    window.open(url, '_blank');
  }
}
