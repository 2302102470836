import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { load } from '@cashfreepayments/cashfree-js';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/shared/services/common.service';
import { ScriptLoaderService } from 'src/shared/services/script-loader.service';
import { UtilityServiceService } from 'src/shared/services/utility-service.service';
@Component({
  selector: 'app-proceed-booking',
  templateUrl: './proceed-booking.component.html',
  styleUrls: ['./proceed-booking.component.scss'],
})
export class proceedBookingComponent implements OnInit {
  @Input() sendData: any = {};

  constructor(
    private commonService: CommonService,
    private messageService: MessageService,
    private utilityService: UtilityServiceService,
    private router: Router,
    private ngZone: NgZone,
    private scriptLoaderService: ScriptLoaderService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) { }

  showLoader: boolean = false;
  showCouponFailMsg: boolean = false;
  showCouponSuccessMsg: boolean = false;
  proceedBookingObj = {
    stationRes: {},
    bookingDate: new Date(),
    selecttedStation: '',
    tourRes: {},
    inclusion: '',
    showDatesPrices: false,
    showPersonalDetail: false,
    locationName: [],
    join: {
      booking_uuid: '',
      iAgree: false,
      part_amount: false,
      bookingData: {
        date: null,
        station: null,
        timeSlots: null,
        tourUUID: null,
        name: null,
        people: [],
        emergencyName: '',
        emergencyNo: '',
        hs_cost: 0,
      },
    },
    heroImages: [],
    reviews: [],
    startTime: '',
    endTime: '',
    currentTime: 0,
    discountPrice: 0,
    promocode: '',
    bookingPrice: 0,
    whatHappensNext: [],
    currency: {},
    paymentModal: false,
    bookingUUID: ""
  };

  paymentType: '';
  rzp: any;
  cashfree: any;
  itinerary: any;
  mobNumberPattern = this.commonService.mobNumberPattern;
  emailPattern = this.commonService.emailPattern;

  ngOnInit(): void {
    let bookingData = JSON.parse(sessionStorage.getItem('bookingData'));
    this.proceedBookingObj.currency = JSON.parse(sessionStorage.getItem('currencyType'));
    if (bookingData) {
      sessionStorage.setItem('sessionData', JSON.stringify(bookingData));
    } else {
      bookingData = sessionStorage.getItem('sessionData');
    }
    if (bookingData) {
      (this.proceedBookingObj.join.bookingData.date = new Date(bookingData['date'])),
        (this.proceedBookingObj.join.bookingData.station = bookingData['station']),
        (this.proceedBookingObj.join.bookingData.timeSlots = bookingData['timeSlots']),
        (this.proceedBookingObj.join.bookingData.tourUUID = bookingData['tourUUID']),
        (this.proceedBookingObj.join.bookingData.name = bookingData['name']),
        (this.proceedBookingObj.join.bookingData.people = bookingData['people']),
        (this.proceedBookingObj.join.bookingData.emergencyName = bookingData['emergencyName']),
        (this.proceedBookingObj.join.bookingData.emergencyNo = bookingData['emergencyNo']);
      this.proceedBookingObj.join.bookingData.hs_cost = bookingData['hs_cost'];
      this.proceedBookingObj.selecttedStation = this.commonService.mydata.station;
      this.getStationByTour();
      this.getTour();
      this.getTourReviews();
      this.getStartEndTime();
      this.proceedBookingObj.currentTime = parseInt(this.proceedBookingObj.startTime);
    } else {
      history.back();
    }
  }

  getStationByTour() {
    this.showLoader=true;
    this.commonService.getStationsByTour(this.proceedBookingObj.join.bookingData.tourUUID).subscribe((res: any) => {
      this.showLoader = false;
      res.data.forEach((element) => {
        if (element.station_uuid == this.proceedBookingObj.selecttedStation) {
          const x = element;
          this.proceedBookingObj.stationRes = element;
        }
      });
    }, (error) => {
      this.showLoader = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server error occured!' });
    });
  }
  getTourReviews() {
    this.commonService.getTourReviews(this.proceedBookingObj.join.bookingData.tourUUID).subscribe((res: any) => {
      this.showLoader = false;
      if (res.code == 'SUCCESS') {
        res.data.forEach((element) => {
          if (!element.parent_review_uuid) {
            this.proceedBookingObj.reviews.push(element);
          }
        });
      } else {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: res.message });
      }
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server error occured!' });
    });
  }
  addBooking() {
    let isValid = true;
    const peopleData = JSON.parse(sessionStorage.getItem('peopleData'));
    if (!(JSON.stringify(peopleData) === JSON.stringify(this.proceedBookingObj.join.bookingData))) {
      if (this.proceedBookingObj.join.bookingData.people.length > 0) {
        this.proceedBookingObj.join.bookingData.people.forEach((p) => {
          if (!p.age || !p.fname) {
            isValid = false;
          }
        });
        if (!this.showCouponFailMsg && !this.showCouponSuccessMsg) {
          let price = this.commonService.convertPrice(this.proceedBookingObj.join.bookingData.hs_cost);
          this.proceedBookingObj.bookingPrice = price * this.proceedBookingObj.join.bookingData['people'].length;
        }
        if (isValid) {
          const firstPerson = this.proceedBookingObj.join.bookingData.people[0];
          if (!firstPerson.email && !firstPerson.mobile) {
            this.utilityService.showFailMessage('Please enter either email or mobile number!');
          } else {
            if (this.proceedBookingObj.join.iAgree) {
              const startDate = new Date(JSON.parse(JSON.stringify(this.proceedBookingObj.join.bookingData.date)));
              const endDate = new Date(JSON.parse(JSON.stringify(this.proceedBookingObj.join.bookingData.date)));
              const startTime = this.proceedBookingObj.join.bookingData.timeSlots.split('to')[0];
              const startHr = startTime.split(':')[0];
              const startMin = startTime.split(':')[1];
              const endTime = this.proceedBookingObj.join.bookingData.timeSlots.split('to')[1];
              const endHr = endTime.split(':')[0];
              const endMin = endTime.split(':')[1];
              startDate.setHours(Number(startHr));
              startDate.setMinutes(Number(startMin));
              endDate.setHours(Number(endHr));
              endDate.setMinutes(Number(endMin));
              const payload = {
                station_uuid: this.proceedBookingObj.join.bookingData.station,
                currency_value: this.proceedBookingObj.currency,
                tour_uuid: this.proceedBookingObj.join.bookingData.tourUUID,
                booked_on: new Date(),
                discount: this.proceedBookingObj.discountPrice,
                start_date: startDate,
                end_date: endDate,
                pp_cost: this.commonService.convertPrice(this.proceedBookingObj.join.bookingData.hs_cost),
                amount: this.proceedBookingObj.bookingPrice,
                length: this.proceedBookingObj.tourRes['navigation'].distance || 0,
                duration: this.proceedBookingObj.tourRes['navigation'].duration || 0,
                noof_bikes_assigned: this.proceedBookingObj.join.bookingData['people'].length || 0,
                noof_guest: this.proceedBookingObj.join.bookingData['people'].length || 0,
                guests: this.proceedBookingObj.join.bookingData['people'],
                emergency_contact_name: this.proceedBookingObj.join.bookingData.emergencyName || '',
                emergency_contact_no: this.proceedBookingObj.join.bookingData.emergencyNo || '',
                guide_assisted: this.proceedBookingObj.tourRes['assistance'].guide || false,
                booking_source: 'WEB',
              };
              this.showLoader = true;
              this.commonService.addBooking(payload).subscribe((res: any) => {
                this.showLoader = false;
                if (res.message == 'BOOKING_ADD_SUCCESS') {
                  this.proceedBookingObj.join.booking_uuid =
                    res.data.booking_uuid;
                  // this.proceedBookingObj.paymentModal = true;
                  this.proceedBookingObj.bookingUUID = res.data.booking_uuid;
                  this.setPaymentOption('CashFree');
                } else {
                  this.messageService.add({ severity: 'info', summary: 'Info', detail: res.message });
                }
              }, (error) => {
                this.showLoader = false;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server error occured!' });
              });
            } else {
              this.showLoader = false;
              this.messageService.add({
                severity: 'error', summary: 'Error', detail: 'Please agree to the terms and conditions!',
              });
            }
          }
        } else {
          this.showLoader = false;
          this.utilityService.showFailMessage('First name and age are mandatory!');
        }
      } else {
        this.utilityService.showFailMessage('No people found!');
      }
    } else {
      this.utilityService.showFailMessage('Booking is done!');
    }
  }

  getTour() {
    this.commonService.getTourByIdV2(this.proceedBookingObj.join.bookingData.tourUUID).subscribe((res: any) => {
      if (res.message == 'GET_TOUR') {
        this.proceedBookingObj.tourRes = res.data;
        if (res.data) {
          this.proceedBookingObj.inclusion = res.data['details'].inclusion.replaceAll('\n\n', '\n').split('\n');
        }
        if (res.data.virtual_experience) {
          if (res.data.virtual_experience?.hero) {
            this.proceedBookingObj.heroImages = res.data.virtual_experience?.hero.filter((ele) => ele.status == 'Active');
          }
        }
        this.getPointDetails();
        this.whatHappensNext();
      } else {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: res.message });
      }
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server error occured!' });
    });
  }

  getPointDetails() {
    this.proceedBookingObj.tourRes['navigation'].points.forEach((element) => {
      this.commonService.getPoint(element).subscribe((res: any) => {
        let timecovered = '';
        timecovered = parseInt(this.proceedBookingObj.startTime) + res.data.details?.duration[0];
        let timeAndLocation = [];
        timeAndLocation.push();
        this.proceedBookingObj.locationName.push(res.data);
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server error occured!' });
      });
    });
  }
  getStartEndTime() {
    if (this.proceedBookingObj.join.bookingData.timeSlots) {
      const startTime = this.proceedBookingObj.join.bookingData.timeSlots.split('to')[0];
      if (startTime) {
        const startHr = startTime.split(':')[0];
        const startMin = startTime.split(':')[1];
        this.proceedBookingObj.startTime = startHr + ':' + startMin;
      }
      const endTime = this.proceedBookingObj.join.bookingData.timeSlots.split('to')[1];
      if (endTime) {
        const endHr = endTime.split(':')[0];
        const endMin = endTime.split(':')[1];
        this.proceedBookingObj.endTime = endHr + ':' + endMin;
      }
    }
  }

  back() {
    this.router.navigate(['/'], {
      queryParams: {
        tour_uuid: this.proceedBookingObj.join.bookingData.tourUUID,
      },
    });
  }

  whatHappensNext() {
    var localTime = this.proceedBookingObj.currentTime;
    var localMinutes = localTime * 60;
    this.proceedBookingObj.tourRes?.['what_happen_nexts'].forEach((element) => {
      var startMins = localMinutes;
      var endMins = startMins + element.duration;
      const startHours = Math.floor(startMins / 60);
      const startMinutes = startMins % 60;
      const endHours = Math.floor(endMins / 60);
      const endMinutes = endMins % 60;

      localMinutes = endMins;
      var obj = {
        time: startHours + ':' + (startMinutes == 0 ? '00' : startMinutes) + ' - ' + endHours + ':' + (endMinutes == 0 ? '00' : endMinutes),
        title: element.title,
        description: element.description,
      };
      this.proceedBookingObj.whatHappensNext.push(obj);
    });
  }
  applyCouponCode() {
    this.proceedBookingObj.discountPrice = 0;
    this.showCouponFailMsg = true;
    this.showCouponSuccessMsg = false;
    let basePrice = this.proceedBookingObj?.join?.bookingData?.hs_cost * this.proceedBookingObj.join.bookingData?.people?.length;
    this.proceedBookingObj.bookingPrice = basePrice;
    const payload = {
      no_of_guest: this.proceedBookingObj.join.bookingData?.people?.length,
      promocode: this.proceedBookingObj.promocode,
      amount: basePrice,
      mobile: this.proceedBookingObj.join.bookingData?.people[0].mobile,
    };
    this.showLoader = true;
    this.commonService.applyCouponCode(payload).subscribe((res: any) => {
      this.showLoader = false;
      if (res.code == 'SUCCESS' && res.message == 'DISCOUNT') {
        this.showCouponFailMsg = false;
        this.showCouponSuccessMsg = true;
        this.proceedBookingObj.discountPrice = res.data;
        this.proceedBookingObj.bookingPrice = this.proceedBookingObj.join.bookingData.hs_cost * this.proceedBookingObj.join.bookingData['people'].length -
          this.proceedBookingObj.discountPrice;
      } else {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: res.message });
      }
    }, (error) => {
      this.showLoader = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server error occured!' });
    });
  }
  checkCouponCode(event: any) {
    if (event.target.value == '') {
      this.proceedBookingObj.discountPrice = 0;
      this.showCouponFailMsg = false;
      this.showCouponSuccessMsg = false;
    } else {
      if (this.showCouponSuccessMsg) {
        this.applyCouponCode();
      }
    }
  }
  gotToPayment() {
    const viewAll = document.getElementById('payment');
    viewAll.scrollIntoView();
  }
  convertPrice(price) {
    return this.commonService.convertPrice(price);;
  }
  setPaymentOption(method: any) {
    this.proceedBookingObj.paymentModal = false;
    if (method == 'RazorPay') {
      this.scriptLoaderService.loadScript('https://checkout.razorpay.com/v1/checkout.js').subscribe(() => {
      });
      this.makePaymentUsingRazorPay();
    } else if (method == 'CashFree') {
      this.scriptLoaderService.loadScript('https://sdk.cashfree.com/js/v3/cashfree.js').subscribe(() => {
      });
      this.makePaymentUsingCashFree();
    }
  }
  makePaymentUsingRazorPay() {
    const payload = {
      amount: this.proceedBookingObj.bookingPrice * 100,
      currency: this.proceedBookingObj.currency['type'],
      booking_uuid: this.proceedBookingObj.bookingUUID,
      paymentType: 'WEB_APP',
      paymentMode: "CASH_FREE",
      part_amount: this.proceedBookingObj.join.part_amount,
    };
    this.showLoader = true;
    this.commonService.createOrder(payload).subscribe((res: any) => {
      this.showLoader = false;
      if (res.message == 'ORDER_CREATED') {
        const options = {
          //key: 'rzp_test_ND7KIY3DG4TMLc',
          key: 'rzp_live_dXznMn6hXH6ftw',
          currency: this.proceedBookingObj.currency['type'],
          name: 'Strode Experiences',
          description: 'Transaction',
          order_id: res.data.razorpay_order_id,
          theme: {
            color: '#017075',
          },
          handler: (handle) => {
            this.router.navigate(['/booking/check-payment-status', res.data.razorpay_order_id, this.proceedBookingObj.bookingUUID]);
          },
        };
        this.rzp = new window.Razorpay(options);
        this.rzp.open();
        this.rzp.on('payment.failed', function (response) {
          location.href =
            'https://www.strodeexperiences.com/#/booking/payment-fail';
        });
      } else {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: res.message });
      }
    }, (error) => {
      this.showLoader = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server error occured!' });
    });
  }
  makePaymentUsingCashFree() {
    const payload = {
      amount: this.proceedBookingObj.bookingPrice * 100,
      currency: this.proceedBookingObj.currency['type'],
      booking_uuid: this.proceedBookingObj.bookingUUID,
      paymentType: 'WEB_APP',
      part_amount: this.proceedBookingObj.join.part_amount,
      paymentMode: "CASH_FREE"
    };
    this.showLoader = true;
    this.commonService.createCashfreeOrder(payload).subscribe(async (res: any) => {
      this.showLoader = false;
      if (res.code == 'SUCCESS' && res.message == 'ORDER_CREATED') {
        this.cashfree = await load({
          // mode: "sandbox"
          mode: "production"
        });
        const paymentOptions = {
          paymentSessionId: res.data['cashfree_session_id'],
          returnUrl: 'https://strodeexperiences.com/#/booking/check-payment-status/' + res.data['razorpay_order_id'] + '/' + this.proceedBookingObj.bookingUUID,
        };
        this.cashfree.checkout(paymentOptions).then(function (result) {
        });
      } else {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: res.message });
      }
    }, (error) => {
      this.showLoader = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server error occured!' });
    });
  }
}
