import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  Inject,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { DOCUMENT, LocationStrategy } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/shared/services/common.service';
import { UtilityServiceService } from 'src/shared/services/utility-service.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.scss'],
})
export class BookingConfirmationComponent implements OnInit, AfterViewInit {
  @ViewChild('headerVideo') headerVideo!: ElementRef;

  showLoader: boolean = false;
  youtubeUrl = '';
  VideosUrl = '';
  bookingConfirmObj = {
    booking_uuid: '',
    booking_id: '',
    tour_uuid: '',
    tour_hero: '',
    city: '',
    bookingConfirmResp: {},

    bookingSuccessResp: {

      guests: {
        fname: '',
        mobile: '',
      },
      payment_log: {},
      station: {
        title: '',
        handled_by_partner: {
          first_name: '',
          last_name: '',
          primary_email_address: '',
          mobile_number: '',
        },
      },
    },
  };
  switchOne = true;
  switchTwo = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private utilityService: UtilityServiceService,
    private commonService: CommonService,
    private renderer: Renderer2,
    private messageService: MessageService,
    private Location: LocationStrategy,
    @Inject(DOCUMENT) private document: Document
  ) {
    history.pushState(null, null, window.location.href);
    this.Location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit(): void {
    const script = this.renderer.createElement('script');
    script.text = `
      gtag('event', 'conversion', {
        'send_to': 'AW-11334778634/_bzoCJHWzfUYEIr-65wq',
        'transaction_id': ''
      });
    `;
    this.renderer.appendChild(this.document.head, script);
    this.showLoader = false;
    this.route.queryParams.subscribe((params) => {
      this.bookingConfirmObj.booking_uuid = params.bookingUUID;
      this.getBookingDetailss(this.bookingConfirmObj.booking_uuid);
    });
  }
  ngAfterViewInit(): void {
    this.headerVideo.nativeElement.muted = true;
  }

  getBookingDetailss(booking_uuid) {
    this.showLoader = true;
    this.commonService.getBookingDetails(booking_uuid).subscribe(
      (response: any) => {
        this.showLoader = false;
        if (response.code == 'SUCCESS' && response.message == 'GET_BOOKING') {
          this.bookingConfirmObj.bookingSuccessResp = response.data;
          this.bookingConfirmObj.booking_id =
            this.bookingConfirmObj.bookingSuccessResp['booking_id'].slice(10);
          this.bookingConfirmObj.tour_uuid = response.data.tour.tour_uuid;
          this.getTour(this.bookingConfirmObj.tour_uuid);
        } else {
          this.messageService.add({ severity: 'info', summary: 'Info', detail: response.message });
        }
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server error occured!' });
      });
  }
  back() {
    this.router.navigate(['/'], {
      queryParams: {
        tour_uuid: this.bookingConfirmObj.tour_uuid,
      },
    });
  }
  switchbox1() {
    this.switchOne = true;
    this.switchTwo = false;
  }
  switchbox2() {
    this.switchOne = false;
    this.switchTwo = true;
  }
  getTour(id) {
    this.commonService.getTourByIdV2(id).subscribe((res: any) => {
      if (res.code == 'SUCCESS' && res.message == 'GET_TOUR') {
        this.bookingConfirmObj.city = res.data.city;
        this.bookingConfirmObj.tour_hero =
          res.data.virtual_experience.hero[0].uuid;
        if (res.data.details?.bg_video != undefined) {
          this.VideosUrl = res.data.details?.bg_video.split(',');
        }
      } else {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: res.message });
      }
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server error occured!' });
    });
  }
  exploreMore() {
    this.router.navigateByUrl(`/things-to-do-in-${this.bookingConfirmObj.city.toLowerCase()}`);
  }
}
