import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public myObj = new BehaviorSubject<any>({});

  private TOUR_SERVICE = 'https://pwdbooking.onnbikes.com/tour-service/';
  IMG_BASEURl = 'https://ik.imagekit.io/strode/';

  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  emailPattern =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
  constructor(private httpClient: HttpClient) { }

  blurElement(event: MouseEvent): void {
    const target = event.target as HTMLButtonElement;
    target.blur();
  }

  getTourDetail(name) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(
      this.TOUR_SERVICE + 'tour/getTourByName/v2/' + name,
      { headers }
    );
  }
  getTourCarousel(name) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(
      this.TOUR_SERVICE + 'tour/getTourCarousel/v2/' + name,
      { headers }
    );
  }

  getStationsByTour(tourUUID) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(
      this.TOUR_SERVICE + 'station/getStationsByTour/v2/' + tourUUID,
      { headers }
    );
  }
  getSimilarTour(tourUUID) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(
      this.TOUR_SERVICE + 'tour/getSimilarTours/v2/' + tourUUID,
      { headers }
    );
  }

  getAvailableSlots(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'booking/getAvailableSlots/v2',
      payload,
      { headers }
    );
  }

  addBooking(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'booking/addBooking/v2',
      payload,
      { headers }
    );
  }

  createOrder(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'payment/createOrder/v2',
      payload,
      { headers }
    );
  }

  createCashfreeOrder(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'cashfree/createOrder/v2',
      payload,
      { headers }
    );
  }

  getPaymentStatus(orderID) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(
      this.TOUR_SERVICE + 'payment/getPaymentStatus/v2/' + orderID,
      { headers }
    );
  }

  getTourReviews(tourUUID) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(
      this.TOUR_SERVICE + 'tour/getTourReviews/v2/' + tourUUID,
      { headers }
    );
  }
  getTourReviewsV3(tourUUID, payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'tour/getTourReviews/v3/' + tourUUID,
      payload, { headers }
    );
  }
  getTourReviewsCount(tourUUID) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(
      this.TOUR_SERVICE + 'tour/getTourReviewsCount/v2/' + tourUUID,
      { headers }
    );
  }

  getBookingDetails(bookingUUId: any) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(
      this.TOUR_SERVICE + 'booking/getBooking/v2/' + bookingUUId,
      { headers }
    );
  }

  getPoint(point_uuid) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(
      this.TOUR_SERVICE + '/points/getPoint/v2/' + point_uuid,
      { headers }
    );
  }

  callback2confirmWeb(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'payment/callback2confirmWeb/v2',
      payload,
      { headers }
    );
  }
  getTourList(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'tour/getTours/v3',
      payload,
      { headers }
    );
  }
  getTrendingAdventures(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'tour/getToursByTag/v2/', payload,
      { headers }
    );
  }
  getHighlyRatedTours(cityname) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(
      this.TOUR_SERVICE + 'tour/getHighlyRatedTours/v2/' + cityname,
      { headers }
    );
  }
  applyCouponCode(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'booking/applyCouponCode/v2',
      payload,
      { headers }
    );
  }
  addEmail(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(this.TOUR_SERVICE + 'email/addEmail', payload, {
      headers,
    });
  }
  addLeads(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(this.TOUR_SERVICE + 'leads/addLead', payload, {
      headers,
    });
  }
  uploadCV(file) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'applications/uploadCV',
      file,
      {
        headers,
      }
    );
  }
  addApplication(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'applications/addApplication',
      payload,
      {
        headers,
      }
    );
  }
  mydata = {
    date: new Date(),
    station: '',
    timeslots: '',
    tour_uuid: '',
    noOfGuest: [],
  };
  getCountryContactCode() {
    return this.httpClient.get('https://restcountries.com/v3.1/all');
  }

  getBlogs(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'blog/getBlogs/v2',
      payload,
      { headers }
    );
  }

  getBlog(uuid) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(this.TOUR_SERVICE + 'blog/getBlog/v2/' + uuid, {
      headers,
    });
  }
  generateOtp(mobile) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(this.TOUR_SERVICE + 'booking/generateOtp/v2/' + mobile, {
      headers,
    });
  }
  verifyOtp(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'booking/verifyOTP/v2/',
      payload,
      { headers }
    );
  }
  getJournals(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'journal/getJournals/v2',
      payload,
      { headers }
    );
  }
  getJournal(journal_uuid) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(
      this.TOUR_SERVICE + 'journal/getJournal/v2/' + journal_uuid,
      { headers }
    );
  }
  getCarousel(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'carousel/getCarousels/v2',
      payload,
      { headers }
    );
  }
  getCategoryTourCount(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'tour/getCategoryTourCount/v2',
      payload,
      { headers }
    );
  }
  getNeighbourhoodPoints(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'points/getNeighbourhoodPoints/v2',
      payload,
      { headers }
    );
  }
  getNeighbourhoodsByCity(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'neighbourhoods/getNeighbourhoodsByCity/v2',
      payload,
      { headers }
    );
  }
  getNeighbourhoodDetails(uuid) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(
      this.TOUR_SERVICE + 'neighbourhoods/getNeighbourhood/v2/' + uuid,
      { headers }
    );
  }
  getPointsCarousels(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'points/getPointsCarousels/v2',
      payload,
      { headers }
    );
  }

  getPointsByTag(payload) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.post(
      this.TOUR_SERVICE + 'points/getPointsByTag/v2',
      payload,
      { headers }
    );
  }
  generateTourName(title, city): string {
    let tourName = '';
    const tempTourName = this.getEncodedTitle(title);
    const words = tempTourName.split('-');

    if (words.length >= 4) {
      tourName = (words[0] + ' ' + words[1] + ' ' + words[2] + ' ' + words[3]).toLowerCase();
    } else {
      tourName = title.toLowerCase();
    }

    tourName = tourName.replace(/ /g, '-');
    tourName = `${tourName}-${city.toLowerCase()}`;
    return tourName;
  }
  getEncodedTitle(name) {
    name = name.toLowerCase();
    name = name.replace(/[^A-Za-z0-9-_가-힣/ ]/g, '');
    name = name.replace(/ /g, '-');
    name = name.replace(/_/g, '-');
    name = name.replace(/ /g, '-');
    name = name.replace(/'/g, '');
    name = name.replace(/\//g, '-');
    name = name.replace(/:/g, '');
    name = name.replace(/%/g, '');
    name = name.replace(/--/g, '-');
    name = name.replace(/--/g, '-');
    if (name.startsWith('-')) {
      name = name.replace(/^-/, '');
    }
    if (name.endsWith('-')) {
      name = name.slice(0, -1);
    }
    return name;
  }
  getDestinationByCity(city) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(
      this.TOUR_SERVICE + 'destination/getDestinationByCity/v2/' + city,
      { headers }
    );
  }
  getUuidByTitle(title) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(
      this.TOUR_SERVICE + 'tour/getUuidByTitle/v1/' + title,
      { headers }
    );
  }
  getUuidByBlogTitle(title) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(
      this.TOUR_SERVICE + 'blog/getUuidByTitle/v1/' + title,
      { headers }
    );
  }
  getTourByIdV2(uuid) {
    let headers = new HttpHeaders();
    headers = headers.set('calling_entity', 'WEB_UI');
    return this.httpClient.get(
      this.TOUR_SERVICE + 'tour/getTour/v2/' + uuid,
      { headers }
    );
  }
  convertPrice(price) {
    let currencyRate = JSON.parse(sessionStorage.getItem('currencyType'));
    if (currencyRate != null) {
      let Cvalue = currencyRate.Cvalue;
      if (Cvalue > 0 && price > 0) {
        let convertedPrice = Cvalue * price;
        return Number(Math.round(convertedPrice * 100.0) / 100.0);

      } else {
        return price;
      }
    } else {
      return price;
    }
  }
  getMasterData(key){
    return this.httpClient.get(this.TOUR_SERVICE+"master/getData/v2/"+key);
  }
  parseJson(data){
    return data.map(item => JSON.parse(item));
  }
}
