<div class="container-fluid " id="web-header">
    <div class="row  p-0 py-0 header" [ngClass]="isFixedHeader?'fixed-header':''">
        <div class="col-md-2  ps-1   logobox">
            <div class="d-flex">
                <div class="d-flex col-lg-7 col-4">
                    <div class="col-5 ps-md-1 ps-0">
                        <span class="text-decoration-none" (click)="navigate('/')"><img
                                [src]="'/element/newlogo_IG7omY2v5.png' | linkPrefix" class="logo" alt=""></span>
                    </div>
                    <div class="col-7">
                        <span class="text-decoration-none" (click)="navigate('/')">
                            <h3 class="TTRegular-font fsize-16 text-white fw-bold py-4 logo-text">STRODE <br>
                                EXPERIENCES </h3>
                        </span>
                    </div>
                </div>
                <div class="text-center">
                    <button (click)="exploremore($event)"
                        class="white-pinkbtn text-uppercase common-button  position-absolute border-0 round-30 text-dark explore-morebtn">Explore
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-8 ps-md-4 pe-md-5 col-12 text-center px-0 my-auto ">
            <div class="px-md-4">
                <header>
                    <div id="menu_button" class="cursor-pointer" (click)="openMenu()">
                        <span id="menulink">
                            <span class="top_bar"></span>
                            <span class="middle_bar"></span>
                            <span class="bottom_bar"></span>
                        </span>
                    </div>
                    <p-accordion id="mobile-menu" [multiple]="false">
                        <div class="text-center">
                            <p-accordionTab (click)="toggleAccordion(0)" [(selected)]="accordionActiveState[0]">
                                <ng-template pTemplate="header">
                                    <div class="text-center w-100 text-white"> WHAT IS STRODE </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <ul>
                                        <li><span (click)="navigate('/what-is-strode/how-we-work')"
                                                class="nav_about text-white">HOW WE WORK</span>
                                        </li>
                                        <li><span (click)="navigate('/what-is-strode/what-we-do')"
                                                class="nav_about text-white">WHAT WE DO</span></li>
                                        <li><span (click)="navigate('/what-is-strode/sustainability')"
                                                class="nav_about text-white">SUSTAINABILITY</span>
                                        </li>
                                        <li><span (click)="navigate('/what-is-strode/self-guided-trips')"
                                                class="nav_about text-white">what is self guided</span>
                                        </li>
                                        <li><span (click)="navigate('/what-is-strode/blogs')"
                                                class="nav_about text-white">BLOGS</span>
                                        </li>
                                    </ul>
                                </ng-template>
                            </p-accordionTab>
                        </div>
                        <p-accordionTab (click)="toggleAccordion(1)" [(selected)]="accordionActiveState[1]">
                            <ng-template pTemplate="header">
                                <div class="text-center w-100 text-white"> DESTINATIONS </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <ul>
                                    <li><span (click)="redirectToCity('lonavala')"
                                            class="nav_about text-white">LONAVALA</span></li>
                                    <li><span (click)="redirectToCity('jaipur')"
                                            class="nav_about text-white">JAIPUR</span>
                                    </li>
                                    <li><span (click)="redirectToCity('udaipur')"
                                            class="nav_about text-white">UDAIPUR</span>
                                    </li>
                                    <li><span (click)="redirectToCity('amsterdam')"
                                            class="nav_about text-white">AMSTERDAM</span></li>
                                </ul>
                            </ng-template>
                        </p-accordionTab>
                        <div class="text-center">
                            <p-accordionTab (click)="toggleAccordion(2)" [(selected)]="accordionActiveState[2]">
                                <ng-template pTemplate="header">
                                    <div class="text-center w-100 text-white"> ALL THE INFO </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <ul>
                                        <li><span (click)="navigate('/all-the-info/our-travel-style')"
                                                class="nav_about text-white">OUR TRAVEL STYLES</span></li>
                                        <li><span (click)="navigate('/all-the-info/join-our-team')"
                                                class="nav_about text-white">JOIN OUR TEAM</span>
                                        </li>
                                        <li><span (click)="navigate('/other/contact-us')"
                                                class="nav_about TTRegular-font fsize-17 text-white">CONTACT US</span>
                                        </li>
                                    </ul>
                                </ng-template>
                            </p-accordionTab>
                        </div>
                        <!-- <div class="text-center">
                            <p-accordionTab (click)="toggleAccordion(3)" [(selected)]="accordionActiveState[3]">
                                <ng-template pTemplate="header">
                                    <div class="currency-dropdown">
                                        <p-dropdown [options]="currency" [(ngModel)]="selectedCurrency"
                                            (onChange)="changeCurrency(selectedCurrency)" optionLabel="type">
                                        </p-dropdown>
                                    </div>
                                </ng-template>
                            </p-accordionTab>
                        </div> -->
                    </p-accordion>
                    <ul id="navlist" class="mob-display-none">
                        <li class="submenu"><span class="nav_about  TTRegular-font fsize-17 ">WHAT IS STRODE</span>
                            <ul>
                                <li [ngClass]="path=='/what-is-strode/how-we-work'?'active':''"><span
                                        (click)="navigate('/what-is-strode/how-we-work')"
                                        class="nav_about TTRegular-font fsize-17">HOW WE WORK</span></li>
                                <li [ngClass]="path=='/what-is-strode/what-we-do'?'active':''"><span
                                        (click)="navigate('/what-is-strode/what-we-do')"
                                        class="nav_about TTRegular-font fsize-17">WHAT WE DO</span></li>
                                <li [ngClass]="path=='/what-is-strode/sustainability'?'active':''"><span
                                        (click)="navigate('/what-is-strode/sustainability')"
                                        class="nav_about TTRegular-font fsize-17">SUSTAINABILITY</span>
                                </li>
                                <li [ngClass]="path=='/what-is-strode/self-guided-trips'?'active':''"><span
                                        (click)="navigate('/what-is-strode/self-guided-trips')"
                                        class="nav_about TTRegular-font fsize-17">WHAT IS SELF GUIDED</span>
                                </li>
                                <li [ngClass]="path=='/what-is-strode/blogs'?'active':''"><span
                                        (click)="navigate('/what-is-strode/blogs')"
                                        class="nav_about TTRegular-font fsize-17">BLOGS</span>
                                </li>
                            </ul>
                        </li>
                        <li class="submenu"><span class="nav_about TTRegular-font fsize-17">DESTINATIONS</span>
                            <ul>
                                <li [ngClass]="path=='/things-to-do-in-lonavala'?'active':''"><span
                                        (click)="redirectToCity('lonavala')"
                                        class="nav_about TTRegular-font fsize-17">LONAVALA</span></li>
                                <li [ngClass]="path=='/things-to-do-in-jaipur'?'active':''"><span
                                        (click)="redirectToCity('jaipur')"
                                        class="nav_about TTRegular-font fsize-17">JAIPUR</span></li>
                                <li [ngClass]="path=='/things-to-do-in-udaipur'?'active':''"><span
                                        (click)="redirectToCity('udaipur')"
                                        class="nav_about TTRegular-font fsize-17">UDAIPUR</span></li>
                                <li [ngClass]="path=='/things-to-do-in-amsterdam'?'active':''"><span
                                        (click)="redirectToCity('amsterdam')"
                                        class="nav_about TTRegular-font fsize-17">AMSTERDAM</span></li>
                            </ul>
                        </li>
                        <li class="submenu"><span class="nav_about TTRegular-font fsize-17">ALL THE INFO</span>
                            <ul>
                                <li [ngClass]="path=='/all-the-info/our-travel-style'?'active':''"><span
                                        (click)="navigate('/all-the-info/our-travel-style')"
                                        class="nav_about TTRegular-font fsize-17">OUR TRAVEL STYLES</span></li>
                                <li [ngClass]="path=='/all-the-info/join-our-team'?'active':''"><span
                                        (click)="navigate('/all-the-info/join-our-team')"
                                        class="nav_about TTRegular-font fsize-17">JOIN OUR TEAM</span>
                                </li>
                                <li [ngClass]="path=='/other/contact-us'?'active':''"><span
                                        (click)="navigate('/other/contact-us')"
                                        class="nav_about TTRegular-font fsize-17">CONTACT US</span></li>
                            </ul>
                        </li>
                        <li>
                            <div class="position-absolute currency-dropdown ">
                                <p-dropdown [options]="currencyList" aria-label="Select currency"
                                    [(ngModel)]="selectedCurrency" aria-label="currency"
                                    (onChange)="changeCurrency(selectedCurrency)" optionLabel="type" optionValue="type">
                                </p-dropdown>
                            </div>
                        </li>
                        <li>
                            <button (click)="exploremore($event)" (mouseenter)="exploreText = 'Explore'"
                                (mouseleave)="exploreText = 'Explore'"
                                class="white-pinkbtn common-button position-absolute text-dark border-0 round-30 explore-morebtn">
                                {{exploreText}} </button>
                        </li>
                    </ul>
                </header>
            </div>
        </div>
    </div>
</div>