import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-web-header',
  templateUrl: './web-header.component.html',
  styleUrls: ['./web-header.component.scss'],
})
export class WebHeaderComponent implements OnInit {
  constructor(private router: Router, private cdr: ChangeDetectorRef, private messageService: MessageService, private commonService: CommonService) { }

  exploreText = "EXPLORE";
  isFixedHeader: boolean = false;
  isScrollingDown: boolean = false;
  previousScrollPosition = 0;
  accordionActiveState = [false, false, false, false];
  currencyList = [];
  selectedCurrency:'INR';
  path = ''

  ngOnInit(): void {
    this.getCurrencyList();
    this.path = this.router.url;
  }

  @HostListener('window:scroll', [])

  onScroll(): void {
    const scrollPosition = window.scrollY;
    this.isFixedHeader = scrollPosition > 0 && scrollPosition < 400;
    this.isScrollingDown = window.scrollY > this.previousScrollPosition;
    this.previousScrollPosition = window.scrollY;
    if (!this.isScrollingDown) {
      this.isFixedHeader = true;
    }
    if (scrollPosition == 0) {
      this.isFixedHeader = false;
    }
  }

  openMenu() {
    document.getElementById('navlist').classList.toggle('open');
    document.getElementById('menulink').classList.toggle('act');
    document.getElementById('mobile-menu').classList.toggle('show');
  }
  redirectToCity(city) {
    this.router.navigateByUrl(`/things-to-do-in-${city}`);
  }

  exploremore(e) {
    this.commonService.blurElement(e);
    this.router.navigate(['/other/explore']);
  }

  toggleAccordion(index: number) {
    this.accordionActiveState = [false, false, false, false];
    this.accordionActiveState[index] = !this.accordionActiveState[index];
  }
  changeCurrency(newCurrency: any) {
    let currency =this.currencyList.find((ele) => ele.type == newCurrency);
    sessionStorage.setItem('currencyType', JSON.stringify(currency));
  }
  getCurrencyList() {
    this.commonService.getMasterData('CURRENCY_VALUE').subscribe((res: any) => {
      if (res.code == 'SUCCESS') {
        this.currencyList =this.commonService.parseJson(res.data);
        this.changeCurrency("INR");
      } else {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: res.message });
      }
    }, (error) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Server error occured!' });
    });
  }
  navigate(url){
    this.router.navigate([url]);
  }
}
